import withStyles from 'isomorphic-style-loader/lib/withStyles';
import PTs from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { setVal } from '../../state/actions/misc';
import { openLoginModal, openSignupModal } from '../../state/actions/modals';

import { getUserProfile } from '../../state/selectors/UserSelectors';

import UserProfileShape from '../../lib/UserProfileShape';

import NavigationAfterLogin from '../NavigationAfterLogin';
import NavigationBeforeLogin from '../NavigationBeforeLogin';

import s from './Navigation.scss';

class Navigation extends React.Component {
  static propTypes = {
    className: PTs.string,
    dispatchOpenLoginModal: PTs.func.isRequired,
    dispatchOpenSignupModal: PTs.func.isRequired,
    displayProfileDropDown: PTs.bool,
    isPageScrolledToTop: PTs.bool.isRequired,
    look: PTs.oneOf(['default', 'secondary']),
    setProp: PTs.func.isRequired,
    userProfile: UserProfileShape,
  };

  static defaultProps = {
    className: '',
    displayProfileDropDown: false,
    look: 'default',
    userProfile: null,
  };

  render() {
    const {
      className,
      dispatchOpenLoginModal,
      dispatchOpenSignupModal,
      displayProfileDropDown,
      isPageScrolledToTop,
      look,
      setProp,
      userProfile,
    } = this.props;

    if (userProfile) {
      return (
        <NavigationAfterLogin
          className={className}
          displayProfileDropDown={displayProfileDropDown}
          isPageScrolledToTop={isPageScrolledToTop}
          look={look}
          setProp={setProp}
          userProfile={userProfile}
        />
      );
    }
    return (
      <NavigationBeforeLogin
        className={className}
        displayProfileDropDown={displayProfileDropDown}
        isPageScrolledToTop={isPageScrolledToTop}
        look={look}
        openLoginModal={dispatchOpenLoginModal}
        openSignupModal={dispatchOpenSignupModal}
        setProp={setProp}
      />
    );
  }
}

const mapStateToProps = state => ({
  ...state.misc,
  userProfile: getUserProfile(state),
});

const mapDispatchToProps = dispatch => ({
  setProp: (key, value) => dispatch(setVal(key, value)),
  dispatchOpenLoginModal: () => {
    const props = {
      redirect: '/dashboard/',
    };
    return dispatch(openLoginModal(props));
  },
  dispatchOpenSignupModal: () => dispatch(openSignupModal()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(withStyles(s)(Navigation)));
