import gql from 'graphql-tag';

const findExpertProfilesQuery = gql`
  query findExpertProfiles($expertId: ID) {
    findExpertProfiles(
      limit: 4
      order: [{ field: "name", dir: asc }]
      predicates: { owner: { id: { eq: $expertId } } }
    ) {
      edges {
        distance
        favorite
        node {
          expert {
            displayName
            firstName
            lastName
            profilePhoto {
              alt
              src
            }
            profilePhotoId
            profilePhotoTypeCode
          }
          media {
            mediaUrl
            mediaType
            mediaSubType
          }
          id
          name
          slug
          upcomingSessionsInfo: sessionsConnection {
            costRangeUsd: costRange {
              max
              min
            }
          }
        }
        ratingInfo {
          count
          score
        }
      }
      totalCount
    }
  }
`;

export default findExpertProfilesQuery;
