import { createSelector } from 'reselect';

/**
 * @param {Object} state - The current state of the application.
 *
 * @returns {UserProfile?} The user profile of the currently logged-in user,
 *   otherwise `null`.
 */
const getUserProfile = state => state.account.data;

/**
 * Determines whether the currently logged-in user is authenticated.
 */
const isAuthenticated = createSelector(
  getUserProfile,
  userProfile => !!userProfile,
);

export { getUserProfile, isAuthenticated };

const UserSelectors = {
  getUserProfile,
  isAuthenticated,
};

export default UserSelectors;
