import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import debounce from 'lodash/debounce';
import PTs from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import HeadBodyFootPageLayout from '../../incubation/components/HeadBodyFootPageLayout';
import {
  Col,
  Grid,
  Row,
} from '../../incubation/components/ResponsiveGridLayout';

import mainCss from '../../styles/main.scss';

import SiteFooter from '../SiteFooter';
import SiteHeader from '../SiteHeader';

import s from './DefaultPageLayout.scss';

class DefaultPageLayout extends React.Component {
  static propTypes = {
    bodyClassName: PTs.string,
    children: PTs.node.isRequired,
    className: PTs.string,
    footClassName: PTs.string,
    headClassName: PTs.string,
    hydrateState: PTs.func,
    look: PTs.oneOf(['default', 'secondary']),
    skipFoot: PTs.bool,
  };

  static defaultProps = {
    bodyClassName: '',
    className: '',
    footClassName: '',
    headClassName: '',
    hydrateState: null,
    look: 'default',
    skipFoot: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      isPageScrolledToTop: true,
    };

    this.handleScroll = debounce(this.handleScroll, 300, {
      leading: true,
      maxWait: 500,
    });
  }

  componentDidMount() {
    this.hydrateState();
    this.handleScroll();
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    this.setState({
      isPageScrolledToTop: window.pageYOffset < 50,
    });
  };

  hydrateState = () => {
    // eslint-disable-next-line
    const stringifiedState = localStorage.getItem('state');
    const state = JSON.parse(stringifiedState);
    if (stringifiedState) {
      if (typeof state === 'object') {
        this.props.hydrateState(state);
      }
    }
  };

  renderRegion = region => {
    const { children, look } = this.props;
    const { isPageScrolledToTop } = this.state;
    if (region === 'head') {
      return (
        <Col>
          <SiteHeader isPageScrolledToTop={isPageScrolledToTop} look={look} />
        </Col>
      );
    }
    if (region === 'foot') {
      return (
        <Grid>
          <Row>
            <Col>
              <SiteFooter />
            </Col>
          </Row>
        </Grid>
      );
    }
    return children;
  };

  render() {
    const {
      bodyClassName,
      className,
      footClassName,
      headClassName,
      look,
      skipFoot,
    } = this.props;
    const { isPageScrolledToTop } = this.state;
    return (
      <React.Fragment>
        <HeadBodyFootPageLayout
          bodyClassName={cx(bodyClassName, 'page__body', s.page__body)}
          footClassName={cx(footClassName, 'page__foot')}
          headClassName={cx(headClassName, 'page__head', s.page__head)}
          isPageScrolledToTop={isPageScrolledToTop}
          look={look}
          rootClassName={cx(className, 'page', s.page)}
          skipFoot={skipFoot}
        >
          {this.renderRegion}
        </HeadBodyFootPageLayout>
      </React.Fragment>
    );
  }
}

const mapStateToProps = null;

const mapDispatchToProps = dispatch => ({
  hydrateState: data => {
    dispatch({
      type: 'HYDRATE_STATE',
      payload: data,
    });
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(mainCss, s)(DefaultPageLayout));
