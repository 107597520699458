import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import PTs from 'prop-types';
import React from 'react';

import s from './HeadBodyFootPageLayout.scss';

class HeadBodyFootPageLayout extends React.Component {
  static propTypes = {
    // The children is expected to be a render-prop.  See
    // https://reactjs.org/docs/render-props.html for details.  It will be called
    // three times with the region names: 'head', 'body', and 'foot'.  The
    // render-prop should return the components to be rendered in each of these
    // regions.
    bodyClassName: PTs.string,
    // NOTE: The following *className properties will be global classes. This makes
    // it easy to tweak the layout specific styles without needing to add theme
    // specific styles to HeadBodyFootPageLayout.scss.
    children: PTs.func.isRequired,
    className: PTs.string,
    footClassName: PTs.string,
    headClassName: PTs.string,
    isPageScrolledToTop: PTs.bool.isRequired,
    look: PTs.oneOf(['default', 'secondary']),
    rootClassName: PTs.string,
    skipBody: PTs.bool,
    skipFoot: PTs.bool,
    skipHead: PTs.bool,
  };

  static defaultProps = {
    // NOTE: Using BEM naming convention.
    bodyClassName: 'page__body',
    className: '',
    footClassName: 'page__foot',
    headClassName: 'page__head',
    look: 'default',
    rootClassName: 'page',
    skipBody: false,
    skipFoot: false,
    skipHead: false,
  };

  headerBackgroundColor = () => {
    const { look, isPageScrolledToTop } = this.props;
    if (look === 'default') {
      if (isPageScrolledToTop) {
        return {
          background: '#fff',
          // boxShadow: '2px 2px 10px 2px rgba(0,0,0,0.05)',
          borderBottom: '1px solid var(--neutral--200)',
        };
      }
      return {
        background: '#fff',
        // boxShadow: '2px 2px 3px 2px rgba(0,0,0,0.05)',
        borderBottom: '1px solid var(--neutral--200)',
      };
    }
    if (isPageScrolledToTop) {
      return {
        background: 'rgba(42,65,79,0)',
      };
    }
    return {
      /* background: 'rgba(42,65,79,1)', */
      background: '#fff',
      borderBottom: '1px solid var(--neutral--200)',
      // boxShadow: '2px 2px 3px 2px rgba(0,0,0,0.05)',
    };
  };

  render() {
    const {
      bodyClassName,
      children,
      className,
      footClassName,
      headClassName,
      rootClassName,
      skipBody,
      skipFoot,
      skipHead,
    } = this.props;
    return (
      <div className={cx(className, rootClassName, s.page)}>
        {!skipHead && (
          <div
            className={cx(headClassName, s.page__head)}
            style={this.headerBackgroundColor()}
          >
            {children('head')}
          </div>
        )}
        {!skipBody && (
          <div className={cx(bodyClassName, s.page__body)}>
            {children('body')}
          </div>
        )}
        {!skipFoot && (
          <div className={cx(footClassName, s.page__foot)}>
            {children('foot')}
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(s)(HeadBodyFootPageLayout);
