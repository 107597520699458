import gql from 'graphql-tag';

// TODO: Trim down the following query to only the data we use.
const getShadowingProfileQuery = gql`
  query getShadowingProfile($slug: String!) {
    findExpertProfiles(predicates: { slug: { eq: $slug } }, limit: 1) {
      edges {
        distance
        favorite
        node {
          aboutYou
          additionalGuidelines
          address
          description
          id
          lat
          lng
          locationTypeCode
          minAge
          name
          slug
          specialties
          toolsAndEquipment
          userId
          willProvide
          expert {
            address
            displayName
            firstName
            lastName
            profilePhoto {
              alt
              altMsgId
              src
              srcSet
            }
            profilePhotoId
            profilePhotoTypeCode
            slug
            userId
          }
          expertiseFields {
            desc
            descMsgId
            expertiseCategory {
              desc
              descMsgId
              id
              img {
                alt
                altMsgId
                src
                srcSet
              }
              name
              nameMsgId
              slug
            }
            expertiseCategoryId
            id
            img {
              alt
              altMsgId
              src
              srcSet
            }
            name
            nameMsgId
            slug
          }
          media {
            id
            caption
            expertProfileId
            cloudinaryId
            mediaSubType
            mediaType
            mediaUrl
          }
          profileFeatures {
            code
            desc
            descMsgId
            id
            name
            nameMsgId
          }
          sessions {
            addCostForTravelUsd
            addCostPerShdwrUsd
            costUsd
            description
            expertProfileId
            id
            maxDistanceMiles
            maxShadowersAllowed
            notes
            title
            dates {
              id
              profileSessionId
              end {
                day
                hours
                milliseconds
                minutes
                month
                seconds
                year
              }
              endIso
              start {
                day
                hours
                milliseconds
                minutes
                month
                seconds
                year
              }
              startIso
              hidden
              bookings {
                id
                numShadowers
                profileSessionDateId
                statusCode
                venueCode
                userId
              }
            }
            types {
              code
              desc
              descMsgId
              id
              name
              nameMsgId
            }
          }
        }
        ratingInfo {
          count
          score
        }
      }
    }
  }
`;

export default getShadowingProfileQuery;
