exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".TVH3h{display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;margin-right:calc(-1 * var(--grid--padding-left-right));margin-right:calc(-1 * var(--grid--padding-left-right));margin-left:calc(-1 * var(--grid--padding-left-right));margin-left:calc(-1 * var(--grid--padding-left-right))}", ""]);

// exports
exports.locals = {
	"row": "TVH3h"
};