import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import React from 'react';
import PTs from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import {
  faAddressCard,
  faEnvelope,
  faCalendarAlt,
  faQuestionCircle,
} from '@fortawesome/free-regular-svg-icons';
import {
  faTachometerAlt,
  faCog,
  faBook,
  faSignOutAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import UserPagesNavSection from '../../router/routes/user/UserPagesNavSection';

import history from '../../../client/history';

import Link from '../Link';
import ProfilePhoto from '../ProfilePhoto';

import messages from '../../locale/messages';

import UserProfileShape from '../../lib/UserProfileShape';

import { logout } from '../../state/actions/AuthenticatedUserActions';

import s from './NavigationAfterLogin.scss';

class NavigationAfterLogin extends React.Component {
  static propTypes = {
    className: PTs.string,
    displayProfileDropDown: PTs.bool,
    isPageScrolledToTop: PTs.bool.isRequired,
    logout: PTs.func.isRequired,
    look: PTs.oneOf(['default', 'secondary']),
    setProp: PTs.func.isRequired,
    userProfile: UserProfileShape.isRequired,
  };

  static defaultProps = {
    className: '',
    displayProfileDropDown: false,
    look: 'default',
  };

  componentWillMount() {
    if (process.env.BROWSER) {
      document.addEventListener('mousedown', this.handleClick, false);
    }
  }

  componentWillUnmount() {
    if (process.env.BROWSER) {
      document.removeEventListener('mousedown', this.handleClick, false);
    }
  }

  handleClickProfile = () => {
    const { setProp, displayProfileDropDown } = this.props;
    setProp('displayProfileDropDown', !displayProfileDropDown);
  };

  handleClick = e => {
    const { displayProfileDropDown } = this.props;
    if (this.node.contains(e.target)) {
      return;
    }

    if (displayProfileDropDown) {
      this.handleClickProfile();
    }
  };

  handleLogoutBtnClick = () => {
    // TODO: Leave the user on the page they logged out from unless they are on a
    // page requiring authentication.
    // Note: The universal router code doesn't get run after logging out and so
    // the pages that require authentication are not redirecting. So, until we
    // find a better solution, we will always redirect to the home page after
    // logging out.
    setTimeout(async () => {
      await this.props.logout();
      this.hideDropDown();
      history.push('');
    }, 1);
  };

  hideDropDown = () => {
    const { setProp } = this.props;
    setProp('displayProfileDropDown', false);
  };

  render() {
    const {
      className,
      displayProfileDropDown,
      isPageScrolledToTop,
      look,
      userProfile,
    } = this.props;

    return (
      <nav
        className={cx(className, s.mainNav)}
        /* eslint-disable-next-line */
        ref={node => (this.node = node)}
      >
        <UserPagesNavSection
          isPageScrolledToTop={isPageScrolledToTop}
          look={look}
        />
        <button
          className={s.profilePicDropDownBtn}
          onClick={this.handleClickProfile}
          type="button"
        >
          <ProfilePhoto height={50} userProfile={userProfile} width={50} />
        </button>
        <div
          className={cx('profile_dropdown', s.profileDropDown)}
          style={
            displayProfileDropDown ? { right: '16px' } : { right: '-260px' }
          }
        >
          <Link
            className={cx(s.link, s.hideLink)}
            onClick={this.hideDropDown}
            to="/user/dashboard"
          >
            <FontAwesomeIcon icon={faTachometerAlt} />
            <span className={s.linkTxt}>Dashboard</span>
          </Link>
          {/* <Link
            className={cx(s.link, s.hideLink)}
            onClick={this.hideDropDown}
            to="user/dashboard/favorites"
          >
            <FontAwesomeIcon icon={faTachometerAlt} />
            <span className={s.linkTxt}>Dashboard</span>
          </Link> */}
          <Link
            className={s.link}
            onClick={this.hideDropDown}
            to="/user/profile"
          >
            <FontAwesomeIcon icon={faAddressCard} />
            <span className={s.linkTxt}>Profiles</span>
          </Link>
          <Link
            className={cx(s.link, s.hideLink)}
            onClick={this.hideDropDown}
            to="/user/inbox"
          >
            <FontAwesomeIcon icon={faEnvelope} />
            <span className={s.linkTxt}>Inbox</span>
          </Link>
          <Link
            className={cx(s.link, s.hideLink)}
            onClick={this.hideDropDown}
            to="/user/calendar/shadower"
          >
            <FontAwesomeIcon icon={faCalendarAlt} />
            <span className={s.linkTxt}>Calendar</span>
          </Link>
          <hr />
          <Link
            className={s.link}
            onClick={this.hideDropDown}
            to="/user/settings"
          >
            <FontAwesomeIcon icon={faCog} />
            <span className={s.linkTxt}>Settings</span>
          </Link>
          <Link className={s.link} onClick={this.hideDropDown} to="/handbook">
            <FontAwesomeIcon icon={faBook} />
            <span className={s.linkTxt}>Handbook</span>
          </Link>
          <Link className={s.link} onClick={this.hideDropDown} to="/help">
            <FontAwesomeIcon icon={faQuestionCircle} />
            <span className={s.linkTxt}>Help</span>
          </Link>
          <hr />
          <button
            className={cx(s.link, s.logoutBtn)}
            onClick={this.handleLogoutBtnClick}
            type="button"
          >
            <FontAwesomeIcon className={s.signOutIcon} icon={faSignOutAlt} />
            <span className={s.linkTxt}>
              <FormattedMessage {...messages.logout} />
            </span>
          </button>
        </div>
      </nav>
    );
  }
}

const mapStateToProps = null;

const mapDispatchToProps = {
  logout,
};

const ReduxAwareNavigationAfterLogin = connect(
  mapStateToProps,
  mapDispatchToProps,
)(NavigationAfterLogin);

export default injectIntl(withStyles(s)(ReduxAwareNavigationAfterLogin));
