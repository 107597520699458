import gql from 'graphql-tag';

const getTopRatedExpertsNearbyQuery = gql`
  query getTopRatedExpertsNearby(
    $limit: Int
    $predicates: FindExpertsPredicatesInput
  ) {
    findExperts(limit: $limit, predicates: $predicates) {
      edges {
        distance
        favorite
        node {
          displayName
          firstName
          lastName
          link {
            to
          }
          profilePhoto {
            alt
            src
          }
          profilePhotoId
          profilePhotoTypeCode
          upcomingSessionsInfo: sessionsConnection {
            costRangeUsd: costRange {
              max
              min
            }
          }
          userId
        }
      }
      totalCount
    }
  }
`;

export default getTopRatedExpertsNearbyQuery;
