exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".page--home ._3w8hQ, .page--home .Z4Hu7{color:#fff}", ""]);

// exports
exports.locals = {
	"link": "_3w8hQ",
	"button": "Z4Hu7"
};