import gql from 'graphql-tag';

const logoutMutation = gql`
  mutation logout {
    logout: logout {
      status
    }
  }
`;

export default logoutMutation;
