import PTs from 'prop-types';

import DateTimeStructShape from './DateTimeStructShape';
import ImgShape from './ImgShape';
import LinkShape from './LinkShape';
import ProfilePhotoTypeCodeShape from './ProfilePhotoTypeCodeShape';

const UserProfileShape = PTs.shape({
  aboutYou: PTs.string.isRequired,
  address: PTs.string.isRequired,
  authLocal: PTs.shape({
    email: PTs.string.isRequired,
  }),
  displayName: PTs.string.isRequired,
  firstName: PTs.string.isRequired,
  joined: DateTimeStructShape.isRequired,
  lastName: PTs.string.isRequired,
  lat: PTs.number,
  link: LinkShape,
  lng: PTs.number,
  payPalLinked: PTs.bool,
  profilePhotoId: PTs.string,
  profilePhotoLg: ImgShape,
  profilePhotoMd: ImgShape,
  profilePhotoOr: ImgShape,
  profilePhotoSm: ImgShape,
  profilePhotoTh: ImgShape,
  profilePhotoTypeCode: ProfilePhotoTypeCodeShape,
  stripeLinked: PTs.bool,
  userId: PTs.string,
});

export default UserProfileShape;
