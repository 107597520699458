import gql from 'graphql-tag';

const getPopularNearbyShadowingsQuery = gql`
  query getPopularNearbyShadowings(
    $limit: Int
    $predicates: FindExpertProfilesPredicatesInput
  ) {
    findExpertProfiles(
      limit: $limit
      predicates: $predicates
      order: [{ field: "name", dir: asc }]
    ) {
      edges {
        distance
        favorite
        node {
          expert {
            displayName
            firstName
            lastName
            profilePhoto {
              alt
              src
            }
            profilePhotoId
            profilePhotoTypeCode
          }
          media {
            mediaUrl
            mediaType
            mediaSubType
          }
          id
          name
          slug
          upcomingSessionsInfo: sessionsConnection {
            costRangeUsd: costRange {
              max
              min
            }
          }
        }
        ratingInfo {
          count
          score
        }
      }
      totalCount
    }
  }
`;

export default getPopularNearbyShadowingsQuery;
