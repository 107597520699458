exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._17K1C{display:inline-block}._17K1C img{width:100%}._17K1C._1SCpn{overflow:hidden;border-radius:50%}._17K1C._1gm5a{border-radius:0}._17K1C ._1JAOx{padding-bottom:100%;background-color:#d2d2d2;border-radius:50%;width:100%}._17K1C ._1JAOx p{text-align:center;margin:0;font-family:\"Nunito-Sans\",\"Nunito-Light\",\"HelveticaNeue-Light\",sans-serif;color:#fff}", ""]);

// exports
exports.locals = {
	"profilePhoto": "_17K1C",
	"circle": "_1SCpn",
	"square": "_1gm5a",
	"default": "_1JAOx"
};