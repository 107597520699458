/*
Note: a relative `imgSrc` is relative to `${assetsRoot}images/`.
*/

import ExpertiseCategories from './ExpertiseCategories';

const ObjectFreeze = Object.freeze;

// ============================================================================
// Art Fields
// ============================================================================

const ART_CATEGORY = ObjectFreeze({
  id: '1000',
  nameEn: ExpertiseCategories.ART.nameEn,
  descEn: ExpertiseCategories.ART.descEn,
  expertiseCategory: ExpertiseCategories.ART,
  expertiseCategoryId: ExpertiseCategories.ART.id,
  nameMsgId: ExpertiseCategories.ART.nameMsgId,
  descMsgId: ExpertiseCategories.ART.descMsgId,
  imageSources: ExpertiseCategories.ART.imageSources,
  imgSrc: ExpertiseCategories.ART.imgSrc,
  slug: 'category',
});

const GLASSWORK = ObjectFreeze({
  id: '1010',
  nameEn: 'Glasswork',
  descEn: 'Art / Glasswork.',
  expertiseCategory: ExpertiseCategories.ART,
  expertiseCategoryId: ExpertiseCategories.ART.id,
  nameMsgId: 'enums.expertise_fields.glasswork.name',
  descMsgId: 'enums.expertise_fields.glasswork.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url: 'shadowing/categories/art/fields/glasswork/glasswork@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url: 'shadowing/categories/art/fields/glasswork/glasswork@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url: 'shadowing/categories/art/fields/glasswork/glasswork@640x400.jpg',
    },
  ]),
  imgSrc: 'shadowing/categories/art/fields/glasswork/glasswork@1280x800.jpg',
  slug: 'glasswork',
});

const GRAPHIC_ART = ObjectFreeze({
  id: '1020',
  nameEn: 'Graphic Art',
  descEn: 'Art / Graphic Art.',
  expertiseCategory: ExpertiseCategories.ART,
  expertiseCategoryId: ExpertiseCategories.ART.id,
  nameMsgId: 'enums.expertise_fields.graphic_art.name',
  descMsgId: 'enums.expertise_fields.graphic_art.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/art/fields/graphic-art/graphic-art@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/art/fields/graphic-art/graphic-art@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/art/fields/graphic-art/graphic-art@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/art/fields/graphic-art/graphic-art@1280x800.jpg',
  slug: 'graphic-art',
});

const METALWORK = ObjectFreeze({
  id: '1030',
  nameEn: 'Metalwork',
  descEn: 'Art / Metalwork.',
  expertiseCategory: ExpertiseCategories.ART,
  expertiseCategoryId: ExpertiseCategories.ART.id,
  nameMsgId: 'enums.expertise_fields.metalwork.name',
  descMsgId: 'enums.expertise_fields.metalwork.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url: 'shadowing/categories/art/fields/metalwork/metalwork@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url: 'shadowing/categories/art/fields/metalwork/metalwork@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url: 'shadowing/categories/art/fields/metalwork/metalwork@640x400.jpg',
    },
  ]),
  imgSrc: 'shadowing/categories/art/fields/metalwork/metalwork@1280x800.jpg',
  slug: 'metalwork',
});

const MULTIMEDIA_ART = ObjectFreeze({
  id: '1040',
  nameEn: 'Multimedia Art',
  descEn: 'Art / Multimedia Art.',
  expertiseCategory: ExpertiseCategories.ART,
  expertiseCategoryId: ExpertiseCategories.ART.id,
  nameMsgId: 'enums.expertise_fields.multimedia_art.name',
  descMsgId: 'enums.expertise_fields.multimedia_art.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/art/fields/multimedia-art/multimedia-art@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/art/fields/multimedia-art/multimedia-art@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/art/fields/multimedia-art/multimedia-art@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/art/fields/multimedia-art/multimedia-art@1280x800.jpg',
  slug: 'multimedia-art',
});

const SCULPTURE = ObjectFreeze({
  id: '1050',
  nameEn: 'Sculpture',
  descEn: 'Art / Sculpture.',
  expertiseCategory: ExpertiseCategories.ART,
  expertiseCategoryId: ExpertiseCategories.ART.id,
  nameMsgId: 'enums.expertise_fields.sculpture.name',
  descMsgId: 'enums.expertise_fields.sculpture.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url: 'shadowing/categories/art/fields/sculpture/sculpture@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url: 'shadowing/categories/art/fields/sculpture/sculpture@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url: 'shadowing/categories/art/fields/sculpture/sculpture@640x400.jpg',
    },
  ]),
  imgSrc: 'shadowing/categories/art/fields/sculpture/sculpture@1280x800.jpg',
  slug: 'sculpture',
});

const WOODCRAFT = ObjectFreeze({
  id: '1060',
  nameEn: 'Woodcraft',
  descEn: 'Art / Woodcraft.',
  expertiseCategory: ExpertiseCategories.ART,
  expertiseCategoryId: ExpertiseCategories.ART.id,
  nameMsgId: 'enums.expertise_fields.woodcraft.name',
  descMsgId: 'enums.expertise_fields.woodcraft.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url: 'shadowing/categories/art/fields/woodcraft/woodcraft@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url: 'shadowing/categories/art/fields/woodcraft/woodcraft@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url: 'shadowing/categories/art/fields/woodcraft/woodcraft@640x400.jpg',
    },
  ]),
  imgSrc: 'shadowing/categories/art/fields/woodcraft/woodcraft@1280x800.jpg',
  slug: 'woodcraft',
});

// ============================================================================
// Business Fields
// ============================================================================

const BUSINESS_CATEGORY = ObjectFreeze({
  id: '2000',
  nameEn: ExpertiseCategories.BUSINESS.nameEn,
  descEn: ExpertiseCategories.BUSINESS.descEn,
  expertiseCategory: ExpertiseCategories.BUSINESS,
  expertiseCategoryId: ExpertiseCategories.BUSINESS.id,
  nameMsgId: ExpertiseCategories.BUSINESS.nameMsgId,
  descMsgId: ExpertiseCategories.BUSINESS.descMsgId,
  imageSources: ExpertiseCategories.BUSINESS.imageSources,
  imgSrc: ExpertiseCategories.BUSINESS.imgSrc,
  slug: 'category',
});

const ENTREPRENEURSHIP = ObjectFreeze({
  id: '2010',
  nameEn: 'Entrepreneurship',
  descEn: 'Business / Entrepreneurship.',
  expertiseCategory: ExpertiseCategories.BUSINESS,
  expertiseCategoryId: ExpertiseCategories.BUSINESS.id,
  nameMsgId: 'enums.expertise_fields.entrepreneurship.name',
  descMsgId: 'enums.expertise_fields.entrepreneurship.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/business/fields/entrepreneurship/entrepreneurship@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/business/fields/entrepreneurship/entrepreneurship@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/business/fields/entrepreneurship/entrepreneurship@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/business/fields/entrepreneurship/entrepreneurship@1280x800.jpg',
  slug: 'entrepreneurship',
});

const FINANCE = ObjectFreeze({
  id: '2020',
  nameEn: 'Finance',
  descEn: 'Business / Finance.',
  expertiseCategory: ExpertiseCategories.BUSINESS,
  expertiseCategoryId: ExpertiseCategories.BUSINESS.id,
  nameMsgId: 'enums.expertise_fields.finance.name',
  descMsgId: 'enums.expertise_fields.finance.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url: 'shadowing/categories/business/fields/finance/finance@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url: 'shadowing/categories/business/fields/finance/finance@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url: 'shadowing/categories/business/fields/finance/finance@640x400.jpg',
    },
  ]),
  imgSrc: 'shadowing/categories/business/fields/finance/finance@1280x800.jpg',
  slug: 'finance',
});

const INVESTING = ObjectFreeze({
  id: '2030',
  nameEn: 'Investing',
  descEn: 'Business / Investing.',
  expertiseCategory: ExpertiseCategories.BUSINESS,
  expertiseCategoryId: ExpertiseCategories.BUSINESS.id,
  nameMsgId: 'enums.expertise_fields.investing.name',
  descMsgId: 'enums.expertise_fields.investing.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/business/fields/investing/investing@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/business/fields/investing/investing@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/business/fields/investing/investing@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/business/fields/investing/investing@1280x800.jpg',
  slug: 'investing',
});

const MANAGEMENT = ObjectFreeze({
  id: '2040',
  nameEn: 'Management',
  descEn: 'Business / Management.',
  expertiseCategory: ExpertiseCategories.BUSINESS,
  expertiseCategoryId: ExpertiseCategories.BUSINESS.id,
  nameMsgId: 'enums.expertise_fields.management.name',
  descMsgId: 'enums.expertise_fields.management.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/business/fields/management/management@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/business/fields/management/management@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/business/fields/management/management@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/business/fields/management/management@1280x800.jpg',
  slug: 'management',
});

const MARKETING = ObjectFreeze({
  id: '2050',
  nameEn: 'Marketing',
  descEn: 'Business / Marketing.',
  expertiseCategory: ExpertiseCategories.BUSINESS,
  expertiseCategoryId: ExpertiseCategories.BUSINESS.id,
  nameMsgId: 'enums.expertise_fields.marketing.name',
  descMsgId: 'enums.expertise_fields.marketing.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/business/fields/marketing/marketing@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/business/fields/marketing/marketing@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/business/fields/marketing/marketing@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/business/fields/marketing/marketing@1280x800.jpg',
  slug: 'marketing',
});

// ============================================================================
// Communication Fields
// ============================================================================

const COMMUNICATION_CATEGORY = ObjectFreeze({
  id: '3000',
  nameEn: ExpertiseCategories.COMMUNICATION.nameEn,
  descEn: ExpertiseCategories.COMMUNICATION.descEn,
  expertiseCategory: ExpertiseCategories.COMMUNICATION,
  expertiseCategoryId: ExpertiseCategories.COMMUNICATION.id,
  nameMsgId: ExpertiseCategories.COMMUNICATION.nameMsgId,
  descMsgId: ExpertiseCategories.COMMUNICATION.descMsgId,
  imageSources: ExpertiseCategories.COMMUNICATION.imageSources,
  imgSrc: ExpertiseCategories.COMMUNICATION.imgSrc,
  slug: 'category',
});

const FICTION_WRITING = ObjectFreeze({
  id: '3010',
  nameEn: 'Fiction Writing',
  descEn: 'Communication / Fiction Writing.',
  expertiseCategory: ExpertiseCategories.COMMUNICATION,
  expertiseCategoryId: ExpertiseCategories.COMMUNICATION.id,
  nameMsgId: 'enums.expertise_fields.fiction_writing.name',
  descMsgId: 'enums.expertise_fields.fiction_writing.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/communication/fields/fiction-writing/fiction-writing@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/communication/fields/fiction-writing/fiction-writing@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/communication/fields/fiction-writing/fiction-writing@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/communication/fields/fiction-writing/fiction-writing@1280x800.jpg',
  slug: 'fiction-writing',
});

const JOURNALISM = ObjectFreeze({
  id: '3020',
  nameEn: 'Journalism',
  descEn: 'Communication / Journalism.',
  expertiseCategory: ExpertiseCategories.COMMUNICATION,
  expertiseCategoryId: ExpertiseCategories.COMMUNICATION.id,
  nameMsgId: 'enums.expertise_fields.journalism.name',
  descMsgId: 'enums.expertise_fields.journalism.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/communication/fields/journalism/journalism@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/communication/fields/journalism/journalism@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/communication/fields/journalism/journalism@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/communication/fields/journalism/journalism@1280x800.jpg',
  slug: 'journalism',
});

const NONFICTION_WRITING = ObjectFreeze({
  id: '3030',
  nameEn: 'Nonfiction Writing',
  descEn: 'Communication / Nonfiction Writing.',
  expertiseCategory: ExpertiseCategories.COMMUNICATION,
  expertiseCategoryId: ExpertiseCategories.COMMUNICATION.id,
  nameMsgId: 'enums.expertise_fields.nonfiction_writing.name',
  descMsgId: 'enums.expertise_fields.nonfiction_writing.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/communication/fields/nonfiction-writing/nonfiction-writing@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/communication/fields/nonfiction-writing/nonfiction-writing@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/communication/fields/nonfiction-writing/nonfiction-writing@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/communication/fields/nonfiction-writing/nonfiction-writing@1280x800.jpg',
  slug: 'nonfiction-writing',
});

const PUBLIC_RELATIONS = ObjectFreeze({
  id: '3040',
  nameEn: 'Public Relations',
  descEn: 'Communication / Public Relations.',
  expertiseCategory: ExpertiseCategories.COMMUNICATION,
  expertiseCategoryId: ExpertiseCategories.COMMUNICATION.id,
  nameMsgId: 'enums.expertise_fields.public_relations.name',
  descMsgId: 'enums.expertise_fields.public_relations.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/communication/fields/public-relations/public-relations@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/communication/fields/public-relations/public-relations@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/communication/fields/public-relations/public-relations@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/communication/fields/public-relations/public-relations@1280x800.jpg',
  slug: 'public-relations',
});

const RADIO_BROADCASTING = ObjectFreeze({
  id: '3050',
  nameEn: 'Radio Broadcasting',
  descEn: 'Communication / Radio Broadcasting.',
  expertiseCategory: ExpertiseCategories.COMMUNICATION,
  expertiseCategoryId: ExpertiseCategories.COMMUNICATION.id,
  nameMsgId: 'enums.expertise_fields.radio_broadcasting.name',
  descMsgId: 'enums.expertise_fields.radio_broadcasting.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/communication/fields/radio-broadcasting/radio-broadcasting@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/communication/fields/radio-broadcasting/radio-broadcasting@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/communication/fields/radio-broadcasting/radio-broadcasting@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/communication/fields/radio-broadcasting/radio-broadcasting@1280x800.jpg',
  slug: 'radio-broadcasting',
});

const TELEVISION_BROADCASTING = ObjectFreeze({
  id: '3060',
  nameEn: 'Television Broadcasting',
  descEn: 'Communication / Television Broadcasting.',
  expertiseCategory: ExpertiseCategories.COMMUNICATION,
  expertiseCategoryId: ExpertiseCategories.COMMUNICATION.id,
  nameMsgId: 'enums.expertise_fields.television_broadcasting.name',
  descMsgId: 'enums.expertise_fields.television_broadcasting.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/communication/fields/television-broadcasting/television-broadcasting@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/communication/fields/television-broadcasting/television-broadcasting@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/communication/fields/television-broadcasting/television-broadcasting@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/communication/fields/television-broadcasting/television-broadcasting@1280x800.jpg',
  slug: 'television-broadcasting',
});

// ============================================================================
// Design Fields
// ============================================================================

const DESIGN_CATEGORY = ObjectFreeze({
  id: '4000',
  nameEn: ExpertiseCategories.DESIGN.nameEn,
  descEn: ExpertiseCategories.DESIGN.descEn,
  expertiseCategory: ExpertiseCategories.DESIGN,
  expertiseCategoryId: ExpertiseCategories.DESIGN.id,
  nameMsgId: ExpertiseCategories.DESIGN.nameMsgId,
  descMsgId: ExpertiseCategories.DESIGN.descMsgId,
  imageSources: ExpertiseCategories.DESIGN.imageSources,
  imgSrc: ExpertiseCategories.DESIGN.imgSrc,
  slug: 'category',
});

const ARCHITECTURAL_DESIGN = ObjectFreeze({
  id: '4010',
  nameEn: 'Architectural Design',
  descEn: 'Design / Architectural Design.',
  expertiseCategory: ExpertiseCategories.DESIGN,
  expertiseCategoryId: ExpertiseCategories.DESIGN.id,
  nameMsgId: 'enums.expertise_fields.architectural_design.name',
  descMsgId: 'enums.expertise_fields.architectural_design.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/design/fields/architectural-design/architectural-design@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/design/fields/architectural-design/architectural-design@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/design/fields/architectural-design/architectural-design@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/design/fields/architectural-design/architectural-design@1280x800.jpg',
  slug: 'architectural-design',
});

const COMPUTER_AIDED_DESIGN = ObjectFreeze({
  id: '4020',
  nameEn: 'Computer Aided Design',
  descEn: 'Design / Computer Aided Design.',
  expertiseCategory: ExpertiseCategories.DESIGN,
  expertiseCategoryId: ExpertiseCategories.DESIGN.id,
  nameMsgId: 'enums.expertise_fields.computer_aided_design.name',
  descMsgId: 'enums.expertise_fields.computer_aided_design.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/design/fields/computer-aided-design/computer-aided-design@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/design/fields/computer-aided-design/computer-aided-design@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/design/fields/computer-aided-design/computer-aided-design@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/design/fields/computer-aided-design/computer-aided-design@1280x800.jpg',
  slug: 'computer-aided-design',
});

const GRAPHIC_DESIGN = ObjectFreeze({
  id: '4030',
  nameEn: 'Graphic Design',
  descEn: 'Design / Graphic Design.',
  expertiseCategory: ExpertiseCategories.DESIGN,
  expertiseCategoryId: ExpertiseCategories.DESIGN.id,
  nameMsgId: 'enums.expertise_fields.graphic_design.name',
  descMsgId: 'enums.expertise_fields.graphic_design.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/design/fields/graphic-design/graphic-design@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/design/fields/graphic-design/graphic-design@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/design/fields/graphic-design/graphic-design@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/design/fields/graphic-design/graphic-design@1280x800.jpg',
  slug: 'graphic-design',
});

const INTERIOR_DESIGN = ObjectFreeze({
  id: '4040',
  nameEn: 'Interior Design',
  descEn: 'Design / Interior Design.',
  expertiseCategory: ExpertiseCategories.DESIGN,
  expertiseCategoryId: ExpertiseCategories.DESIGN.id,
  nameMsgId: 'enums.expertise_fields.interior_design.name',
  descMsgId: 'enums.expertise_fields.interior_design.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/design/fields/interior-design/interior-design@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/design/fields/interior-design/interior-design@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/design/fields/interior-design/interior-design@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/design/fields/interior-design/interior-design@1280x800.jpg',
  slug: 'interior-design',
});

const PRODUCT_DESIGN = ObjectFreeze({
  id: '4050',
  nameEn: 'Product Design',
  descEn: 'Design / Product Design.',
  expertiseCategory: ExpertiseCategories.DESIGN,
  expertiseCategoryId: ExpertiseCategories.DESIGN.id,
  nameMsgId: 'enums.expertise_fields.product_design.name',
  descMsgId: 'enums.expertise_fields.product_design.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/design/fields/product-design/product-design@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/design/fields/product-design/product-design@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/design/fields/product-design/product-design@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/design/fields/product-design/product-design@1280x800.jpg',
  slug: 'product-design',
});

const UI_UX_DESIGN = ObjectFreeze({
  id: '4060',
  nameEn: 'UI/UX Design',
  descEn: 'Design / UI/UX Design.',
  expertiseCategory: ExpertiseCategories.DESIGN,
  expertiseCategoryId: ExpertiseCategories.DESIGN.id,
  nameMsgId: 'enums.expertise_fields.ui_ux_design.name',
  descMsgId: 'enums.expertise_fields.ui_ux_design.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/design/fields/ui-ux-design/ui-ux-design@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/design/fields/ui-ux-design/ui-ux-design@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/design/fields/ui-ux-design/ui-ux-design@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/design/fields/ui-ux-design/ui-ux-design@1280x800.jpg',
  slug: 'ui-ux-design',
});

// ============================================================================
// Lifestyle Fields
// ============================================================================

const LIFESTYLE_CATEGORY = ObjectFreeze({
  id: '5000',
  nameEn: ExpertiseCategories.LIFESTYLE.nameEn,
  descEn: ExpertiseCategories.LIFESTYLE.descEn,
  expertiseCategory: ExpertiseCategories.LIFESTYLE,
  expertiseCategoryId: ExpertiseCategories.LIFESTYLE.id,
  nameMsgId: ExpertiseCategories.LIFESTYLE.nameMsgId,
  descMsgId: ExpertiseCategories.LIFESTYLE.descMsgId,
  imageSources: ExpertiseCategories.LIFESTYLE.imageSources,
  imgSrc: ExpertiseCategories.LIFESTYLE.imgSrc,
  slug: 'category',
});

const CRAFTS = ObjectFreeze({
  id: '5010',
  nameEn: 'Crafts',
  descEn: 'Lifestyle / Crafts.',
  expertiseCategory: ExpertiseCategories.LIFESTYLE,
  expertiseCategoryId: ExpertiseCategories.LIFESTYLE.id,
  nameMsgId: 'enums.expertise_fields.crafts.name',
  descMsgId: 'enums.expertise_fields.crafts.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url: 'shadowing/categories/lifestyle/fields/crafts/crafts@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url: 'shadowing/categories/lifestyle/fields/crafts/crafts@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url: 'shadowing/categories/lifestyle/fields/crafts/crafts@640x400.jpg',
    },
  ]),
  imgSrc: 'shadowing/categories/lifestyle/fields/crafts/crafts@1280x800.jpg',
  slug: 'crafts',
});

const EVENT_PLANNING = ObjectFreeze({
  id: '5020',
  nameEn: 'Event Planning',
  descEn: 'Lifestyle / Event Planning.',
  expertiseCategory: ExpertiseCategories.LIFESTYLE,
  expertiseCategoryId: ExpertiseCategories.LIFESTYLE.id,
  nameMsgId: 'enums.expertise_fields.event_planning.name',
  descMsgId: 'enums.expertise_fields.event_planning.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/lifestyle/fields/event-planning/event-planning@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/lifestyle/fields/event-planning/event-planning@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/lifestyle/fields/event-planning/event-planning@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/lifestyle/fields/event-planning/event-planning@1280x800.jpg',
  slug: 'event-planning',
});

const FASHION_AND_BEAUTY = ObjectFreeze({
  id: '5030',
  nameEn: 'Fashion and Beauty',
  descEn: 'Lifestyle / Fashion and Beauty.',
  expertiseCategory: ExpertiseCategories.LIFESTYLE,
  expertiseCategoryId: ExpertiseCategories.LIFESTYLE.id,
  nameMsgId: 'enums.expertise_fields.fashion_and_beauty.name',
  descMsgId: 'enums.expertise_fields.fashion_and_beauty.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/lifestyle/fields/fashion-and-beauty/fashion-and-beauty@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/lifestyle/fields/fashion-and-beauty/fashion-and-beauty@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/lifestyle/fields/fashion-and-beauty/fashion-and-beauty@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/lifestyle/fields/fashion-and-beauty/fashion-and-beauty@1280x800.jpg',
  slug: 'fashion-and-beauty',
});

const FOOD_AND_DRINK = ObjectFreeze({
  id: '5040',
  nameEn: 'Food and Drink',
  descEn: 'Lifestyle / Food and Drink.',
  expertiseCategory: ExpertiseCategories.LIFESTYLE,
  expertiseCategoryId: ExpertiseCategories.LIFESTYLE.id,
  nameMsgId: 'enums.expertise_fields.food_and_drink.name',
  descMsgId: 'enums.expertise_fields.food_and_drink.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/lifestyle/fields/food-and-drink/food-and-drink@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/lifestyle/fields/food-and-drink/food-and-drink@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/lifestyle/fields/food-and-drink/food-and-drink@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/lifestyle/fields/food-and-drink/food-and-drink@1280x800.jpg',
  slug: 'food-and-drink',
});

const HEALTH_AND_WELLNESS = ObjectFreeze({
  id: '5050',
  nameEn: 'Health and Wellness',
  descEn: 'Lifestyle / Health and Wellness.',
  expertiseCategory: ExpertiseCategories.LIFESTYLE,
  expertiseCategoryId: ExpertiseCategories.LIFESTYLE.id,
  nameMsgId: 'enums.expertise_fields.health_and_wellness.name',
  descMsgId: 'enums.expertise_fields.health_and_wellness.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/lifestyle/fields/health-and-wellness/health-and-wellness@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/lifestyle/fields/health-and-wellness/health-and-wellness@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/lifestyle/fields/health-and-wellness/health-and-wellness@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/lifestyle/fields/health-and-wellness/health-and-wellness@1280x800.jpg',
  slug: 'health-and-wellness',
});

const RELIGION_AND_SPIRITUALITY = ObjectFreeze({
  id: '5060',
  nameEn: 'Religion and Spirituality',
  descEn: 'Lifestyle / Religion and Spirituality.',
  expertiseCategory: ExpertiseCategories.LIFESTYLE,
  expertiseCategoryId: ExpertiseCategories.LIFESTYLE.id,
  nameMsgId: 'enums.expertise_fields.religion_and_spirituality.name',
  descMsgId: 'enums.expertise_fields.religion_and_spirituality.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/lifestyle/fields/religion-and-spirituality/religion-and-spirituality@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/lifestyle/fields/religion-and-spirituality/religion-and-spirituality@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/lifestyle/fields/religion-and-spirituality/religion-and-spirituality@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/lifestyle/fields/religion-and-spirituality/religion-and-spirituality@1280x800.jpg',
  slug: 'religion-and-spirituality',
});

// ============================================================================
// Math and Science Fields
// ============================================================================

const MATH_AND_SCIENCE_CATEGORY = ObjectFreeze({
  id: '6000',
  nameEn: ExpertiseCategories.MATH_AND_SCIENCE.nameEn,
  descEn: ExpertiseCategories.MATH_AND_SCIENCE.descEn,
  expertiseCategory: ExpertiseCategories.MATH_AND_SCIENCE,
  expertiseCategoryId: ExpertiseCategories.MATH_AND_SCIENCE.id,
  nameMsgId: ExpertiseCategories.MATH_AND_SCIENCE.nameMsgId,
  descMsgId: ExpertiseCategories.MATH_AND_SCIENCE.descMsgId,
  imageSources: ExpertiseCategories.MATH_AND_SCIENCE.imageSources,
  imgSrc: ExpertiseCategories.MATH_AND_SCIENCE.imgSrc,
  slug: 'category',
});

const COMPUTER_SCIENCE = ObjectFreeze({
  id: '6010',
  nameEn: 'Computer Science',
  descEn: 'Math and Science / Computer Science.',
  expertiseCategory: ExpertiseCategories.MATH_AND_SCIENCE,
  expertiseCategoryId: ExpertiseCategories.MATH_AND_SCIENCE.id,
  nameMsgId: 'enums.expertise_fields.computer_science.name',
  descMsgId: 'enums.expertise_fields.computer_science.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/math-and-science/fields/computer-science/computer-science@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/math-and-science/fields/computer-science/computer-science@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/math-and-science/fields/computer-science/computer-science@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/math-and-science/fields/computer-science/computer-science@1280x800.jpg',
  slug: 'computer-science',
});

const ENGINEERING = ObjectFreeze({
  id: '6020',
  nameEn: 'Engineering',
  descEn: 'Math and Science / Engineering.',
  expertiseCategory: ExpertiseCategories.MATH_AND_SCIENCE,
  expertiseCategoryId: ExpertiseCategories.MATH_AND_SCIENCE.id,
  nameMsgId: 'enums.expertise_fields.engineering.name',
  descMsgId: 'enums.expertise_fields.engineering.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/math-and-science/fields/engineering/engineering@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/math-and-science/fields/engineering/engineering@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/math-and-science/fields/engineering/engineering@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/math-and-science/fields/engineering/engineering@1280x800.jpg',
  slug: 'engineering',
});

const LIFE_SCIENCE = ObjectFreeze({
  id: '6030',
  nameEn: 'Life Science',
  descEn: 'Math and Science / Life Science.',
  expertiseCategory: ExpertiseCategories.MATH_AND_SCIENCE,
  expertiseCategoryId: ExpertiseCategories.MATH_AND_SCIENCE.id,
  nameMsgId: 'enums.expertise_fields.life_science.name',
  descMsgId: 'enums.expertise_fields.life_science.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/math-and-science/fields/life-science/life-science@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/math-and-science/fields/life-science/life-science@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/math-and-science/fields/life-science/life-science@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/math-and-science/fields/life-science/life-science@1280x800.jpg',
  slug: 'life-science',
});

const MATHEMATICS = ObjectFreeze({
  id: '6040',
  nameEn: 'Mathematics',
  descEn: 'Math and Science / Mathematics.',
  expertiseCategory: ExpertiseCategories.MATH_AND_SCIENCE,
  expertiseCategoryId: ExpertiseCategories.MATH_AND_SCIENCE.id,
  nameMsgId: 'enums.expertise_fields.mathematics.name',
  descMsgId: 'enums.expertise_fields.mathematics.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/math-and-science/fields/mathematics/mathematics@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/math-and-science/fields/mathematics/mathematics@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/math-and-science/fields/mathematics/mathematics@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/math-and-science/fields/mathematics/mathematics@1280x800.jpg',
  slug: 'mathematics',
});

const PHYSICAL_SCIENCE = ObjectFreeze({
  id: '6050',
  nameEn: 'Physical Science',
  descEn: 'Math and Science / Physical Science.',
  expertiseCategory: ExpertiseCategories.MATH_AND_SCIENCE,
  expertiseCategoryId: ExpertiseCategories.MATH_AND_SCIENCE.id,
  nameMsgId: 'enums.expertise_fields.physical_science.name',
  descMsgId: 'enums.expertise_fields.physical_science.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/math-and-science/fields/physical-science/physical-science@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/math-and-science/fields/physical-science/physical-science@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/math-and-science/fields/physical-science/physical-science@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/math-and-science/fields/physical-science/physical-science@1280x800.jpg',
  slug: 'physical-science',
});

const SOCIAL_SCIENCE = ObjectFreeze({
  id: '6060',
  nameEn: 'Social Science',
  descEn: 'Math and Science / Social Science.',
  expertiseCategory: ExpertiseCategories.MATH_AND_SCIENCE,
  expertiseCategoryId: ExpertiseCategories.MATH_AND_SCIENCE.id,
  nameMsgId: 'enums.expertise_fields.social_science.name',
  descMsgId: 'enums.expertise_fields.social_science.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/math-and-science/fields/social-science/social-science@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/math-and-science/fields/social-science/social-science@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/math-and-science/fields/social-science/social-science@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/math-and-science/fields/social-science/social-science@1280x800.jpg',
  slug: 'social-science',
});

// ============================================================================
// Music Fields
// ============================================================================

const MUSIC_CATEGORY = ObjectFreeze({
  id: '7000',
  nameEn: ExpertiseCategories.MUSIC.nameEn,
  descEn: ExpertiseCategories.MUSIC.descEn,
  expertiseCategory: ExpertiseCategories.MUSIC,
  expertiseCategoryId: ExpertiseCategories.MUSIC.id,
  nameMsgId: ExpertiseCategories.MUSIC.nameMsgId,
  descMsgId: ExpertiseCategories.MUSIC.descMsgId,
  imageSources: ExpertiseCategories.MUSIC.imageSources,
  imgSrc: ExpertiseCategories.MUSIC.imgSrc,
  slug: 'category',
});

const AUDIO_ENGINEERING = ObjectFreeze({
  id: '7010',
  nameEn: 'Audio Engineering',
  descEn: 'Music / Audio Engineering.',
  expertiseCategory: ExpertiseCategories.MUSIC,
  expertiseCategoryId: ExpertiseCategories.MUSIC.id,
  nameMsgId: 'enums.expertise_fields.audio_engineering.name',
  descMsgId: 'enums.expertise_fields.audio_engineering.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/music/fields/audio-engineering/audio-engineering@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/music/fields/audio-engineering/audio-engineering@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/music/fields/audio-engineering/audio-engineering@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/music/fields/audio-engineering/audio-engineering@1280x800.jpg',
  slug: 'audio-engineering',
});

const LIVE_PERFORMANCE = ObjectFreeze({
  id: '7020',
  nameEn: 'Live Performance',
  descEn: 'Music / Live Performance.',
  expertiseCategory: ExpertiseCategories.MUSIC,
  expertiseCategoryId: ExpertiseCategories.MUSIC.id,
  nameMsgId: 'enums.expertise_fields.live_performance.name',
  descMsgId: 'enums.expertise_fields.live_performance.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/music/fields/live-performance/live-performance@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/music/fields/live-performance/live-performance@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/music/fields/live-performance/live-performance@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/music/fields/live-performance/live-performance@1280x800.jpg',
  slug: 'live-performance',
});

const MASTERING = ObjectFreeze({
  id: '7030',
  nameEn: 'Mastering',
  descEn: 'Music / Mastering.',
  expertiseCategory: ExpertiseCategories.MUSIC,
  expertiseCategoryId: ExpertiseCategories.MUSIC.id,
  nameMsgId: 'enums.expertise_fields.mastering.name',
  descMsgId: 'enums.expertise_fields.mastering.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/music/fields/mastering/mastering@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url: 'shadowing/categories/music/fields/mastering/mastering@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url: 'shadowing/categories/music/fields/mastering/mastering@640x400.jpg',
    },
  ]),
  imgSrc: 'shadowing/categories/music/fields/mastering/mastering@1280x800.jpg',
  slug: 'mastering',
});

const MIXING = ObjectFreeze({
  id: '7040',
  nameEn: 'Mixing',
  descEn: 'Music / Mixing.',
  expertiseCategory: ExpertiseCategories.MUSIC,
  expertiseCategoryId: ExpertiseCategories.MUSIC.id,
  nameMsgId: 'enums.expertise_fields.mixing.name',
  descMsgId: 'enums.expertise_fields.mixing.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url: 'shadowing/categories/music/fields/mixing/mixing@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url: 'shadowing/categories/music/fields/mixing/mixing@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url: 'shadowing/categories/music/fields/mixing/mixing@640x400.jpg',
    },
  ]),
  imgSrc: 'shadowing/categories/music/fields/mixing/mixing@1280x800.jpg',
  slug: 'mixing',
});

const MUSIC_PRODUCTION = ObjectFreeze({
  id: '7050',
  nameEn: 'Music Production',
  descEn: 'Music / Music Production.',
  expertiseCategory: ExpertiseCategories.MUSIC,
  expertiseCategoryId: ExpertiseCategories.MUSIC.id,
  nameMsgId: 'enums.expertise_fields.music_production.name',
  descMsgId: 'enums.expertise_fields.music_production.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/music/fields/music-production/music-production@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/music/fields/music-production/music-production@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/music/fields/music-production/music-production@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/music/fields/music-production/music-production@1280x800.jpg',
  slug: 'music-production',
});

const RECORDING = ObjectFreeze({
  id: '7060',
  nameEn: 'Recording',
  descEn: 'Music / Recording.',
  expertiseCategory: ExpertiseCategories.MUSIC,
  expertiseCategoryId: ExpertiseCategories.MUSIC.id,
  nameMsgId: 'enums.expertise_fields.recording.name',
  descMsgId: 'enums.expertise_fields.recording.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/music/fields/recording/recording@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url: 'shadowing/categories/music/fields/recording/recording@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url: 'shadowing/categories/music/fields/recording/recording@640x400.jpg',
    },
  ]),
  imgSrc: 'shadowing/categories/music/fields/recording/recording@1280x800.jpg',
  slug: 'recording',
});

const SOUND_DESIGN = ObjectFreeze({
  id: '7070',
  nameEn: 'Sound Design',
  descEn: 'Music / Sound Design.',
  expertiseCategory: ExpertiseCategories.MUSIC,
  expertiseCategoryId: ExpertiseCategories.MUSIC.id,
  nameMsgId: 'enums.expertise_fields.sound_design.name',
  descMsgId: 'enums.expertise_fields.sound_design.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/music/fields/sound-design/sound-design@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/music/fields/sound-design/sound-design@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/music/fields/sound-design/sound-design@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/music/fields/sound-design/sound-design@1280x800.jpg',
  slug: 'sound-design',
});

// ============================================================================
// Performing Arts Fields
// ============================================================================

const PERFORMING_ARTS_CATEGORY = ObjectFreeze({
  id: '8000',
  nameEn: ExpertiseCategories.PERFORMING_ARTS.nameEn,
  descEn: ExpertiseCategories.PERFORMING_ARTS.descEn,
  expertiseCategory: ExpertiseCategories.PERFORMING_ARTS,
  expertiseCategoryId: ExpertiseCategories.PERFORMING_ARTS.id,
  nameMsgId: ExpertiseCategories.PERFORMING_ARTS.nameMsgId,
  descMsgId: ExpertiseCategories.PERFORMING_ARTS.descMsgId,
  imageSources: ExpertiseCategories.PERFORMING_ARTS.imageSources,
  imgSrc: ExpertiseCategories.PERFORMING_ARTS.imgSrc,
  slug: 'category',
});

const COMEDY = ObjectFreeze({
  id: '8010',
  nameEn: 'Comedy',
  descEn: 'Comedy.',
  expertiseCategory: ExpertiseCategories.PERFORMING_ARTS,
  expertiseCategoryId: ExpertiseCategories.PERFORMING_ARTS.id,
  nameMsgId: 'enums.expertise_fields.comedy.name',
  descMsgId: 'enums.expertise_fields.comedy.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/performing-arts/fields/comedy/comedy@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/performing-arts/fields/comedy/comedy@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/performing-arts/fields/comedy/comedy@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/performing-arts/fields/comedy/comedy@1280x800.jpg',
  slug: 'comedy',
});

const DANCE = ObjectFreeze({
  id: '8020',
  nameEn: 'Dance',
  descEn: 'Dance.',
  expertiseCategory: ExpertiseCategories.PERFORMING_ARTS,
  expertiseCategoryId: ExpertiseCategories.PERFORMING_ARTS.id,
  nameMsgId: 'enums.expertise_fields.dance.name',
  descMsgId: 'enums.expertise_fields.dance.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/performing-arts/fields/dance/dance@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/performing-arts/fields/dance/dance@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/performing-arts/fields/dance/dance@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/performing-arts/fields/dance/dance@1280x800.jpg',
  slug: 'dance',
});

const JUGGLING = ObjectFreeze({
  id: '8030',
  nameEn: 'Juggling',
  descEn: 'Juggling.',
  expertiseCategory: ExpertiseCategories.PERFORMING_ARTS,
  expertiseCategoryId: ExpertiseCategories.PERFORMING_ARTS.id,
  nameMsgId: 'enums.expertise_fields.juggling.name',
  descMsgId: 'enums.expertise_fields.juggling.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/performing-arts/fields/juggling/juggling@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/performing-arts/fields/juggling/juggling@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/performing-arts/fields/juggling/juggling@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/performing-arts/fields/juggling/juggling@1280x800.jpg',
  slug: 'juggling',
});

const MAGIC = ObjectFreeze({
  id: '8040',
  nameEn: 'Magic',
  descEn: 'Magic.',
  expertiseCategory: ExpertiseCategories.PERFORMING_ARTS,
  expertiseCategoryId: ExpertiseCategories.PERFORMING_ARTS.id,
  nameMsgId: 'enums.expertise_fields.magic.name',
  descMsgId: 'enums.expertise_fields.magic.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/performing-arts/fields/magic/magic@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/performing-arts/fields/magic/magic@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/performing-arts/fields/magic/magic@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/performing-arts/fields/magic/magic@1280x800.jpg',
  slug: 'magic',
});

const THEATER = ObjectFreeze({
  id: '8050',
  nameEn: 'Theater',
  descEn: 'Theater.',
  expertiseCategory: ExpertiseCategories.PERFORMING_ARTS,
  expertiseCategoryId: ExpertiseCategories.PERFORMING_ARTS.id,
  nameMsgId: 'enums.expertise_fields.theater.name',
  descMsgId: 'enums.expertise_fields.theater.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/performing-arts/fields/theater/theater@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/performing-arts/fields/theater/theater@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/performing-arts/fields/theater/theater@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/performing-arts/fields/theater/theater@1280x800.jpg',
  slug: 'theater',
});

// ============================================================================
// Photography Fields
// ============================================================================

const PHOTOGRAPHY_CATEGORY = ObjectFreeze({
  id: '9000',
  nameEn: ExpertiseCategories.PHOTOGRAPHY.nameEn,
  descEn: ExpertiseCategories.PHOTOGRAPHY.descEn,
  expertiseCategory: ExpertiseCategories.PHOTOGRAPHY,
  expertiseCategoryId: ExpertiseCategories.PHOTOGRAPHY.id,
  nameMsgId: ExpertiseCategories.PHOTOGRAPHY.nameMsgId,
  descMsgId: ExpertiseCategories.PHOTOGRAPHY.descMsgId,
  imageSources: ExpertiseCategories.PHOTOGRAPHY.imageSources,
  imgSrc: ExpertiseCategories.PHOTOGRAPHY.imgSrc,
  slug: 'category',
});

const AERIAL_PHOTOGRAPHY = ObjectFreeze({
  id: '9010',
  nameEn: 'Aerial',
  descEn: 'Aerial Photography.',
  expertiseCategory: ExpertiseCategories.PHOTOGRAPHY,
  expertiseCategoryId: ExpertiseCategories.PHOTOGRAPHY.id,
  nameMsgId: 'enums.expertise_fields.aerial_photography.name',
  descMsgId: 'enums.expertise_fields.aerial_photography.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/photography/fields/aerial-photography/aerial-photography@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/photography/fields/aerial-photography/aerial-photography@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/photography/fields/aerial-photography/aerial-photography@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/photography/fields/aerial-photography/aerial-photography@1280x800.jpg',
  slug: 'aerial-photography',
});

const ASTROPHOTOGRAPHY = ObjectFreeze({
  id: '9020',
  nameEn: 'Astrophotography',
  descEn: 'Astrophotography.',
  expertiseCategory: ExpertiseCategories.PHOTOGRAPHY,
  expertiseCategoryId: ExpertiseCategories.PHOTOGRAPHY.id,
  nameMsgId: 'enums.expertise_fields.astrophotography.name',
  descMsgId: 'enums.expertise_fields.astrophotography.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/photography/fields/astrophotography/astrophotography@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/photography/fields/astrophotography/astrophotography@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/photography/fields/astrophotography/astrophotography@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/photography/fields/astrophotography/astrophotography@1280x800.jpg',
  slug: 'astrophotography',
});

const COMMERCIAL_PHOTOGRAPHY = ObjectFreeze({
  id: '9030',
  nameEn: 'Commercial',
  descEn: 'Commercial Photography.',
  expertiseCategory: ExpertiseCategories.PHOTOGRAPHY,
  expertiseCategoryId: ExpertiseCategories.PHOTOGRAPHY.id,
  nameMsgId: 'enums.expertise_fields.commercial_photography.name',
  descMsgId: 'enums.expertise_fields.commercial_photography.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/photography/fields/commercial-photography/commercial-photography@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/photography/fields/commercial-photography/commercial-photography@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/photography/fields/commercial-photography/commercial-photography@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/photography/fields/commercial-photography/commercial-photography@1280x800.jpg',
  slug: 'commercial-photography',
});

const EVENT_PHOTOGRAPHY = ObjectFreeze({
  id: '9040',
  nameEn: 'Event Photography',
  descEn: 'Event Photography.',
  expertiseCategory: ExpertiseCategories.PHOTOGRAPHY,
  expertiseCategoryId: ExpertiseCategories.PHOTOGRAPHY.id,
  nameMsgId: 'enums.expertise_fields.event_photography.name',
  descMsgId: 'enums.expertise_fields.event_photography.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/photography/fields/event-photography/event-photography@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/photography/fields/event-photography/event-photography@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/photography/fields/event-photography/event-photography@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/photography/fields/event-photography/event-photography@1280x800.jpg',
  slug: 'event-photography',
});

const NATURE_AND_WILDLIFE = ObjectFreeze({
  id: '9050',
  nameEn: 'Nature and Wildlife',
  descEn: 'Nature and Wildlife.',
  expertiseCategory: ExpertiseCategories.PHOTOGRAPHY,
  expertiseCategoryId: ExpertiseCategories.PHOTOGRAPHY.id,
  nameMsgId: 'enums.expertise_fields.nature_and_wildlife.name',
  descMsgId: 'enums.expertise_fields.nature_and_wildlife.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/photography/fields/nature-and-wildlife/nature-and-wildlife@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/photography/fields/nature-and-wildlife/nature-and-wildlife@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/photography/fields/nature-and-wildlife/nature-and-wildlife@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/photography/fields/nature-and-wildlife/nature-and-wildlife@1280x800.jpg',
  slug: 'nature-and-wildlife',
});

const PHOTO_EDITING = ObjectFreeze({
  id: '9060',
  nameEn: 'Photo Editing',
  descEn: 'Photo Editing.',
  expertiseCategory: ExpertiseCategories.PHOTOGRAPHY,
  expertiseCategoryId: ExpertiseCategories.PHOTOGRAPHY.id,
  nameMsgId: 'enums.expertise_fields.photo_editing.name',
  descMsgId: 'enums.expertise_fields.photo_editing.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/photography/fields/photo-editing/photo-editing@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/photography/fields/photo-editing/photo-editing@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/photography/fields/photo-editing/photo-editing@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/photography/fields/photo-editing/photo-editing@1280x800.jpg',
  slug: 'photo-editing',
});

// ============================================================================
// Society Fields
// ============================================================================

const SOCIETY_CATEGORY = ObjectFreeze({
  id: '10000',
  nameEn: ExpertiseCategories.SOCIETY.nameEn,
  descEn: ExpertiseCategories.SOCIETY.descEn,
  expertiseCategory: ExpertiseCategories.SOCIETY,
  expertiseCategoryId: ExpertiseCategories.SOCIETY.id,
  nameMsgId: ExpertiseCategories.SOCIETY.nameMsgId,
  descMsgId: ExpertiseCategories.SOCIETY.descMsgId,
  imageSources: ExpertiseCategories.SOCIETY.imageSources,
  imgSrc: ExpertiseCategories.SOCIETY.imgSrc,
  slug: 'category',
});

const EDUCATION = ObjectFreeze({
  id: '10010',
  nameEn: 'Education',
  descEn: 'Society / Education.',
  expertiseCategory: ExpertiseCategories.SOCIETY,
  expertiseCategoryId: ExpertiseCategories.SOCIETY.id,
  nameMsgId: 'enums.expertise_fields.education.name',
  descMsgId: 'enums.expertise_fields.education.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/society/fields/education/education@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/society/fields/education/education@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/society/fields/education/education@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/society/fields/education/education@1280x800.jpg',
  slug: 'education',
});

const ENVIRONMENT_AND_SUSTAINABILITY = ObjectFreeze({
  id: '10020',
  nameEn: 'Environment and Sustainability',
  descEn: 'Society / Environment and Sustainability.',
  expertiseCategory: ExpertiseCategories.SOCIETY,
  expertiseCategoryId: ExpertiseCategories.SOCIETY.id,
  nameMsgId: 'enums.expertise_fields.environment_and_sustainability.name',
  descMsgId: 'enums.expertise_fields.environment_and_sustainability.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/society/fields/environment-and-sustainability/environment-and-sustainability@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/society/fields/environment-and-sustainability/environment-and-sustainability@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/society/fields/environment-and-sustainability/environment-and-sustainability@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/society/fields/environment-and-sustainability/environment-and-sustainability@1280x800.jpg',
  slug: 'environment-and-sustainability',
});

const GOVERNMENT = ObjectFreeze({
  id: '10030',
  nameEn: 'Government',
  descEn: 'Society / Government.',
  expertiseCategory: ExpertiseCategories.SOCIETY,
  expertiseCategoryId: ExpertiseCategories.SOCIETY.id,
  nameMsgId: 'enums.expertise_fields.government.name',
  descMsgId: 'enums.expertise_fields.government.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/society/fields/government/government@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/society/fields/government/government@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/society/fields/government/government@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/society/fields/government/government@1280x800.jpg',
  slug: 'government',
});

const LAW = ObjectFreeze({
  id: '10040',
  nameEn: 'Law',
  descEn: 'Society / Law.',
  expertiseCategory: ExpertiseCategories.SOCIETY,
  expertiseCategoryId: ExpertiseCategories.SOCIETY.id,
  nameMsgId: 'enums.expertise_fields.law.name',
  descMsgId: 'enums.expertise_fields.law.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url: 'shadowing/categories/society/fields/law/law@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url: 'shadowing/categories/society/fields/law/law@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url: 'shadowing/categories/society/fields/law/law@640x400.jpg',
    },
  ]),
  imgSrc: 'shadowing/categories/society/fields/law/law@1280x800.jpg',
  slug: 'law',
});

const NONPROFIT_ORGANIZATIONS = ObjectFreeze({
  id: '10050',
  nameEn: 'Nonprofit Organizations',
  descEn: 'Society / Nonprofit Organizations.',
  expertiseCategory: ExpertiseCategories.SOCIETY,
  expertiseCategoryId: ExpertiseCategories.SOCIETY.id,
  nameMsgId: 'enums.expertise_fields.nonprofit_organizations.name',
  descMsgId: 'enums.expertise_fields.nonprofit_organizations.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/society/fields/nonprofit-organizations/nonprofit-organizations@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/society/fields/nonprofit-organizations/nonprofit-organizations@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/society/fields/nonprofit-organizations/nonprofit-organizations@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/society/fields/nonprofit-organizations/nonprofit-organizations@1280x800.jpg',
  slug: 'nonprofit-organizations',
});

// ============================================================================
// Sports and Recreation Fields
// ============================================================================

const SPORTS_AND_RECREATION_CATEGORY = ObjectFreeze({
  id: '11000',
  nameEn: ExpertiseCategories.SPORTS_AND_RECREATION.nameEn,
  descEn: ExpertiseCategories.SPORTS_AND_RECREATION.descEn,
  expertiseCategory: ExpertiseCategories.SPORTS_AND_RECREATION,
  expertiseCategoryId: ExpertiseCategories.SPORTS_AND_RECREATION.id,
  nameMsgId: ExpertiseCategories.SPORTS_AND_RECREATION.nameMsgId,
  descMsgId: ExpertiseCategories.SPORTS_AND_RECREATION.descMsgId,
  imageSources: ExpertiseCategories.SPORTS_AND_RECREATION.imageSources,
  imgSrc: ExpertiseCategories.SPORTS_AND_RECREATION.imgSrc,
  slug: 'category',
});

const ADVENTURE_SPORTS = ObjectFreeze({
  id: '11010',
  nameEn: 'Adventure Sports',
  descEn: 'Sports and Recreation / Adventure Sports.',
  expertiseCategory: ExpertiseCategories.SPORTS_AND_RECREATION,
  expertiseCategoryId: ExpertiseCategories.SPORTS_AND_RECREATION.id,
  nameMsgId: 'enums.expertise_fields.adventure_sports.name',
  descMsgId: 'enums.expertise_fields.adventure_sports.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/sports-and-recreation/fields/adventure-sports/adventure-sports@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/sports-and-recreation/fields/adventure-sports/adventure-sports@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/sports-and-recreation/fields/adventure-sports/adventure-sports@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/sports-and-recreation/fields/adventure-sports/adventure-sports@1280x800.jpg',
  slug: 'adventure-sports',
});

const EXTREME_SPORTS = ObjectFreeze({
  id: '11020',
  nameEn: 'Extreme Sports',
  descEn: 'Sports and Recreation / Extreme Sports.',
  expertiseCategory: ExpertiseCategories.SPORTS_AND_RECREATION,
  expertiseCategoryId: ExpertiseCategories.SPORTS_AND_RECREATION.id,
  nameMsgId: 'enums.expertise_fields.extreme_sports.name',
  descMsgId: 'enums.expertise_fields.extreme_sports.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/sports-and-recreation/fields/extreme-sports/extreme-sports@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/sports-and-recreation/fields/extreme-sports/extreme-sports@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/sports-and-recreation/fields/extreme-sports/extreme-sports@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/sports-and-recreation/fields/extreme-sports/extreme-sports@1280x800.jpg',
  slug: 'extreme-sports',
});

const FITNESS_AND_EXERCISE = ObjectFreeze({
  id: '11030',
  nameEn: 'Fitness and Exercise',
  descEn: 'Sports and Recreation / Fitness and Exercise.',
  expertiseCategory: ExpertiseCategories.SPORTS_AND_RECREATION,
  expertiseCategoryId: ExpertiseCategories.SPORTS_AND_RECREATION.id,
  nameMsgId: 'enums.expertise_fields.fitness_and_exercise.name',
  descMsgId: 'enums.expertise_fields.fitness_and_exercise.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/sports-and-recreation/fields/fitness-and-exercise/fitness-and-exercise@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/sports-and-recreation/fields/fitness-and-exercise/fitness-and-exercise@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/sports-and-recreation/fields/fitness-and-exercise/fitness-and-exercise@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/sports-and-recreation/fields/fitness-and-exercise/fitness-and-exercise@1280x800.jpg',
  slug: 'fitness-and-exercise',
});

const MOTOR_SPORTS = ObjectFreeze({
  id: '11040',
  nameEn: 'Motor Sports',
  descEn: 'Sports and Recreation / Motor Sports.',
  expertiseCategory: ExpertiseCategories.SPORTS_AND_RECREATION,
  expertiseCategoryId: ExpertiseCategories.SPORTS_AND_RECREATION.id,
  nameMsgId: 'enums.expertise_fields.motor_sports.name',
  descMsgId: 'enums.expertise_fields.motor_sports.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/sports-and-recreation/fields/motor-sports/motor-sports@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/sports-and-recreation/fields/motor-sports/motor-sports@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/sports-and-recreation/fields/motor-sports/motor-sports@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/sports-and-recreation/fields/motor-sports/motor-sports@1280x800.jpg',
  slug: 'motor-sports',
});

const SUMMER_SPORTS = ObjectFreeze({
  id: '11050',
  nameEn: 'Summer Sports',
  descEn: 'Sports and Recreation / Summer Sports.',
  expertiseCategory: ExpertiseCategories.SPORTS_AND_RECREATION,
  expertiseCategoryId: ExpertiseCategories.SPORTS_AND_RECREATION.id,
  nameMsgId: 'enums.expertise_fields.summer_sports.name',
  descMsgId: 'enums.expertise_fields.summer_sports.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/sports-and-recreation/fields/summer-sports/summer-sports@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/sports-and-recreation/fields/summer-sports/summer-sports@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/sports-and-recreation/fields/summer-sports/summer-sports@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/sports-and-recreation/fields/summer-sports/summer-sports@1280x800.jpg',
  slug: 'summer-sports',
});

const WINTER_SPORTS = ObjectFreeze({
  id: '11060',
  nameEn: 'Winter Sports',
  descEn: 'Sports and Recreation / Winter Sports.',
  expertiseCategory: ExpertiseCategories.SPORTS_AND_RECREATION,
  expertiseCategoryId: ExpertiseCategories.SPORTS_AND_RECREATION.id,
  nameMsgId: 'enums.expertise_fields.winter_sports.name',
  descMsgId: 'enums.expertise_fields.winter_sports.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/sports-and-recreation/fields/winter-sports/winter-sports@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/sports-and-recreation/fields/winter-sports/winter-sports@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/sports-and-recreation/fields/winter-sports/winter-sports@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/sports-and-recreation/fields/winter-sports/winter-sports@1280x800.jpg',
  slug: 'winter-sports',
});

// ============================================================================
// Technology Fields
// ============================================================================

const TECHNOLOGY_CATEGORY = ObjectFreeze({
  id: '12000',
  nameEn: ExpertiseCategories.TECHNOLOGY.nameEn,
  descEn: ExpertiseCategories.TECHNOLOGY.descEn,
  expertiseCategory: ExpertiseCategories.TECHNOLOGY,
  expertiseCategoryId: ExpertiseCategories.TECHNOLOGY.id,
  nameMsgId: ExpertiseCategories.TECHNOLOGY.nameMsgId,
  descMsgId: ExpertiseCategories.TECHNOLOGY.descMsgId,
  imageSources: ExpertiseCategories.TECHNOLOGY.imageSources,
  imgSrc: ExpertiseCategories.TECHNOLOGY.imgSrc,
  slug: 'category',
});

const APP_DEVELOPMENT = ObjectFreeze({
  id: '12010',
  nameEn: 'App Development',
  descEn: 'Technology / App Development.',
  expertiseCategory: ExpertiseCategories.TECHNOLOGY,
  expertiseCategoryId: ExpertiseCategories.TECHNOLOGY.id,
  nameMsgId: 'enums.expertise_fields.app_development.name',
  descMsgId: 'enums.expertise_fields.app_development.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/technology/fields/app-development/app-development@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/technology/fields/app-development/app-development@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/technology/fields/app-development/app-development@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/technology/fields/app-development/app-development@1280x800.jpg',
  slug: 'app-development',
});

const ARTIFICIAL_INTELLIGENCE = ObjectFreeze({
  id: '12020',
  nameEn: 'Artificial Intelligence',
  descEn: 'Technology / Artificial Intelligence.',
  expertiseCategory: ExpertiseCategories.TECHNOLOGY,
  expertiseCategoryId: ExpertiseCategories.TECHNOLOGY.id,
  nameMsgId: 'enums.expertise_fields.artificial_intelligence.name',
  descMsgId: 'enums.expertise_fields.artificial_intelligence.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/technology/fields/artificial-intelligence/artificial-intelligence@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/technology/fields/artificial-intelligence/artificial-intelligence@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/technology/fields/artificial-intelligence/artificial-intelligence@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/technology/fields/artificial-intelligence/artificial-intelligence@1280x800.jpg',
  slug: 'artificial-intelligence',
});

const GAME_DEVELOPMENT = ObjectFreeze({
  id: '12030',
  nameEn: 'Game Development',
  descEn: 'Technology / Game Development.',
  expertiseCategory: ExpertiseCategories.TECHNOLOGY,
  expertiseCategoryId: ExpertiseCategories.TECHNOLOGY.id,
  nameMsgId: 'enums.expertise_fields.game_development.name',
  descMsgId: 'enums.expertise_fields.game_development.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/technology/fields/game-development/game-development@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/technology/fields/game-development/game-development@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/technology/fields/game-development/game-development@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/technology/fields/game-development/game-development@1280x800.jpg',
  slug: 'game-development',
});

const NETWORK_AND_SECURITY = ObjectFreeze({
  id: '12040',
  nameEn: 'Network and Security',
  descEn: 'Technology / Network and Security.',
  expertiseCategory: ExpertiseCategories.TECHNOLOGY,
  expertiseCategoryId: ExpertiseCategories.TECHNOLOGY.id,
  nameMsgId: 'enums.expertise_fields.network_and_security.name',
  descMsgId: 'enums.expertise_fields.network_and_security.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/technology/fields/network-and-security/network-and-security@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/technology/fields/network-and-security/network-and-security@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/technology/fields/network-and-security/network-and-security@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/technology/fields/network-and-security/network-and-security@1280x800.jpg',
  slug: 'network-and-security',
});

const ROBOTICS = ObjectFreeze({
  id: '12050',
  nameEn: 'Robotics',
  descEn: 'Technology / Robotics.',
  expertiseCategory: ExpertiseCategories.TECHNOLOGY,
  expertiseCategoryId: ExpertiseCategories.TECHNOLOGY.id,
  nameMsgId: 'enums.expertise_fields.robotics.name',
  descMsgId: 'enums.expertise_fields.robotics.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/technology/fields/robotics/robotics@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/technology/fields/robotics/robotics@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/technology/fields/robotics/robotics@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/technology/fields/robotics/robotics@1280x800.jpg',
  slug: 'robotics',
});

const SOFTWARE_ENGINEERING = ObjectFreeze({
  id: '12060',
  nameEn: 'Software Engineering',
  descEn: 'Technology / Software Engineering.',
  expertiseCategory: ExpertiseCategories.TECHNOLOGY,
  expertiseCategoryId: ExpertiseCategories.TECHNOLOGY.id,
  nameMsgId: 'enums.expertise_fields.software_engineering.name',
  descMsgId: 'enums.expertise_fields.software_engineering.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/technology/fields/software-engineering/software-engineering@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/technology/fields/software-engineering/software-engineering@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/technology/fields/software-engineering/software-engineering@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/technology/fields/software-engineering/software-engineering@1280x800.jpg',
  slug: 'software-engineering',
});

const WEB_DEVELOPMENT = ObjectFreeze({
  id: '12070',
  nameEn: 'Web Development',
  descEn: 'Technology / Web Development.',
  expertiseCategory: ExpertiseCategories.TECHNOLOGY,
  expertiseCategoryId: ExpertiseCategories.TECHNOLOGY.id,
  nameMsgId: 'enums.expertise_fields.web_development.name',
  descMsgId: 'enums.expertise_fields.web_development.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/technology/fields/web-development/web-development@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/technology/fields/web-development/web-development@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/technology/fields/web-development/web-development@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/technology/fields/web-development/web-development@1280x800.jpg',
  slug: 'web-development',
});

// ============================================================================
// Trade Skills Fields
// ============================================================================

const TRADE_SKILLS_CATEGORY = ObjectFreeze({
  id: '13000',
  nameEn: ExpertiseCategories.TRADE_SKILLS.nameEn,
  descEn: ExpertiseCategories.TRADE_SKILLS.descEn,
  expertiseCategory: ExpertiseCategories.TRADE_SKILLS,
  expertiseCategoryId: ExpertiseCategories.TRADE_SKILLS.id,
  nameMsgId: ExpertiseCategories.TRADE_SKILLS.nameMsgId,
  descMsgId: ExpertiseCategories.TRADE_SKILLS.descMsgId,
  imageSources: ExpertiseCategories.TRADE_SKILLS.imageSources,
  imgSrc: ExpertiseCategories.TRADE_SKILLS.imgSrc,
  slug: 'category',
});

const AUTOMOTIVE = ObjectFreeze({
  id: '13010',
  nameEn: 'Automotive',
  descEn: 'Trade Skills / Automotive.',
  expertiseCategory: ExpertiseCategories.TRADE_SKILLS,
  expertiseCategoryId: ExpertiseCategories.TRADE_SKILLS.id,
  nameMsgId: 'enums.expertise_fields.automotive.name',
  descMsgId: 'enums.expertise_fields.automotive.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/trade-skills/fields/automotive/automotive@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/trade-skills/fields/automotive/automotive@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/trade-skills/fields/automotive/automotive@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/trade-skills/fields/automotive/automotive@1280x800.jpg',
  slug: 'automotive',
});

const AVIATION = ObjectFreeze({
  id: '13020',
  nameEn: 'Aviation',
  descEn: 'Trade Skills / Aviation.',
  expertiseCategory: ExpertiseCategories.TRADE_SKILLS,
  expertiseCategoryId: ExpertiseCategories.TRADE_SKILLS.id,
  nameMsgId: 'enums.expertise_fields.aviation.name',
  descMsgId: 'enums.expertise_fields.aviation.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/trade-skills/fields/aviation/aviation@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/trade-skills/fields/aviation/aviation@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/trade-skills/fields/aviation/aviation@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/trade-skills/fields/aviation/aviation@1280x800.jpg',
  slug: 'aviation',
});

const CONSTRUCTION = ObjectFreeze({
  id: '13030',
  nameEn: 'Construction',
  descEn: 'Trade Skills / Construction.',
  expertiseCategory: ExpertiseCategories.TRADE_SKILLS,
  expertiseCategoryId: ExpertiseCategories.TRADE_SKILLS.id,
  nameMsgId: 'enums.expertise_fields.construction.name',
  descMsgId: 'enums.expertise_fields.construction.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/trade-skills/fields/construction/construction@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/trade-skills/fields/construction/construction@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/trade-skills/fields/construction/construction@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/trade-skills/fields/construction/construction@1280x800.jpg',
  slug: 'construction',
});

const ELECTRICAL = ObjectFreeze({
  id: '13040',
  nameEn: 'Electrical',
  descEn: 'Trade Skills / Electrical.',
  expertiseCategory: ExpertiseCategories.TRADE_SKILLS,
  expertiseCategoryId: ExpertiseCategories.TRADE_SKILLS.id,
  nameMsgId: 'enums.expertise_fields.electrical.name',
  descMsgId: 'enums.expertise_fields.electrical.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/trade-skills/fields/electrical/electrical@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/trade-skills/fields/electrical/electrical@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/trade-skills/fields/electrical/electrical@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/trade-skills/fields/electrical/electrical@1280x800.jpg',
  slug: 'electrical',
});

const HOME_IMPROVEMENT = ObjectFreeze({
  id: '13050',
  nameEn: 'Home Improvement',
  descEn: 'Trade Skills / Home Improvement.',
  expertiseCategory: ExpertiseCategories.TRADE_SKILLS,
  expertiseCategoryId: ExpertiseCategories.TRADE_SKILLS.id,
  nameMsgId: 'enums.expertise_fields.home_improvement.name',
  descMsgId: 'enums.expertise_fields.home_improvement.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/trade-skills/fields/home-improvement/home-improvement@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/trade-skills/fields/home-improvement/home-improvement@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/trade-skills/fields/home-improvement/home-improvement@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/trade-skills/fields/home-improvement/home-improvement@1280x800.jpg',
  slug: 'home-improvement',
});

const MANUFACTURING = ObjectFreeze({
  id: '13060',
  nameEn: 'Manufacturing',
  descEn: 'Trade Skills / Manufacturing.',
  expertiseCategory: ExpertiseCategories.TRADE_SKILLS,
  expertiseCategoryId: ExpertiseCategories.TRADE_SKILLS.id,
  nameMsgId: 'enums.expertise_fields.manufacturing.name',
  descMsgId: 'enums.expertise_fields.manufacturing.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/trade-skills/fields/manufacturing/manufacturing@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/trade-skills/fields/manufacturing/manufacturing@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/trade-skills/fields/manufacturing/manufacturing@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/trade-skills/fields/manufacturing/manufacturing@1280x800.jpg',
  slug: 'manufacturing',
});

const MECHANICAL = ObjectFreeze({
  id: '13070',
  nameEn: 'Mechanical',
  descEn: 'Trade Skills / Mechanical.',
  expertiseCategory: ExpertiseCategories.TRADE_SKILLS,
  expertiseCategoryId: ExpertiseCategories.TRADE_SKILLS.id,
  nameMsgId: 'enums.expertise_fields.mechanical.name',
  descMsgId: 'enums.expertise_fields.mechanical.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/trade-skills/fields/mechanical/mechanical@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/trade-skills/fields/mechanical/mechanical@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/trade-skills/fields/mechanical/mechanical@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/trade-skills/fields/mechanical/mechanical@1280x800.jpg',
  slug: 'mechanical',
});

// ============================================================================
// Video Fields
// ============================================================================

const VIDEO_CATEGORY = ObjectFreeze({
  id: '14000',
  nameEn: ExpertiseCategories.VIDEO.nameEn,
  descEn: ExpertiseCategories.VIDEO.descEn,
  expertiseCategory: ExpertiseCategories.VIDEO,
  expertiseCategoryId: ExpertiseCategories.VIDEO.id,
  nameMsgId: ExpertiseCategories.VIDEO.nameMsgId,
  descMsgId: ExpertiseCategories.VIDEO.descMsgId,
  imageSources: ExpertiseCategories.VIDEO.imageSources,
  imgSrc: ExpertiseCategories.VIDEO.imgSrc,
  slug: 'category',
});

const ANIMATION = ObjectFreeze({
  id: '14010',
  nameEn: 'Animation',
  descEn: 'Video / Animation.',
  expertiseCategory: ExpertiseCategories.VIDEO,
  expertiseCategoryId: ExpertiseCategories.VIDEO.id,
  nameMsgId: 'enums.expertise_fields.animation.name',
  descMsgId: 'enums.expertise_fields.animation.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/video/fields/animation/animation@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url: 'shadowing/categories/video/fields/animation/animation@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url: 'shadowing/categories/video/fields/animation/animation@640x400.jpg',
    },
  ]),
  imgSrc: 'shadowing/categories/video/fields/animation/animation@1280x800.jpg',
  slug: 'animation',
});

const CGI_MODELING = ObjectFreeze({
  id: '14020',
  nameEn: 'CGI Modeling',
  descEn: 'CGI modeling.',
  expertiseCategory: ExpertiseCategories.VIDEO,
  expertiseCategoryId: ExpertiseCategories.VIDEO.id,
  nameMsgId: 'enums.expertise_fields.cgi_modeling.name',
  descMsgId: 'enums.expertise_fields.cgi_modeling.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/video/fields/cgi-modeling/cgi-modeling@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/video/fields/cgi-modeling/cgi-modeling@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/video/fields/cgi-modeling/cgi-modeling@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/video/fields/cgi-modeling/cgi-modeling@1280x800.jpg',
  slug: 'cgi-modeling',
});

const POST_PRODUCTION = ObjectFreeze({
  id: '14030',
  nameEn: 'Post-Production',
  descEn: 'Post-Production.',
  expertiseCategory: ExpertiseCategories.VIDEO,
  expertiseCategoryId: ExpertiseCategories.VIDEO.id,
  nameMsgId: 'enums.expertise_fields.post_production.name',
  descMsgId: 'enums.expertise_fields.post_production.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/video/fields/post-production/post-production@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/video/fields/post-production/post-production@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/video/fields/post-production/post-production@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/video/fields/post-production/post-production@1280x800.jpg',
  slug: 'post-production',
});

const SCREENWRITING = ObjectFreeze({
  id: '14040',
  nameEn: 'Screenwriting',
  descEn: 'Screenwriting.',
  expertiseCategory: ExpertiseCategories.VIDEO,
  expertiseCategoryId: ExpertiseCategories.VIDEO.id,
  nameMsgId: 'enums.expertise_fields.screenwriting.name',
  descMsgId: 'enums.expertise_fields.screenwriting.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/video/fields/screenwriting/screenwriting@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/video/fields/screenwriting/screenwriting@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/video/fields/screenwriting/screenwriting@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/video/fields/screenwriting/screenwriting@1280x800.jpg',
  slug: 'screenwriting',
});

const VIDEO_EDITING = ObjectFreeze({
  id: '14050',
  nameEn: 'Video Editing',
  descEn: 'Video Editing.',
  expertiseCategory: ExpertiseCategories.VIDEO,
  expertiseCategoryId: ExpertiseCategories.VIDEO.id,
  nameMsgId: 'enums.expertise_fields.video_editing.name',
  descMsgId: 'enums.expertise_fields.video_editing.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/video/fields/video-editing/video-editing@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/video/fields/video-editing/video-editing@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/video/fields/video-editing/video-editing@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/video/fields/video-editing/video-editing@1280x800.jpg',
  slug: 'video-editing',
});

const VIDEO_PRODUCTION = ObjectFreeze({
  id: '14060',
  nameEn: 'Video Production',
  descEn: 'Video Production.',
  expertiseCategory: ExpertiseCategories.VIDEO,
  expertiseCategoryId: ExpertiseCategories.VIDEO.id,
  nameMsgId: 'enums.expertise_fields.video_production.name',
  descMsgId: 'enums.expertise_fields.video_production.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/video/fields/video-production/video-production@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/video/fields/video-production/video-production@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/video/fields/video-production/video-production@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/video/fields/video-production/video-production@1280x800.jpg',
  slug: 'video-production',
});

const VISUAL_EFFECTS = ObjectFreeze({
  id: '14070',
  nameEn: 'Visual Effects',
  descEn: 'Visual Effects.',
  expertiseCategory: ExpertiseCategories.VIDEO,
  expertiseCategoryId: ExpertiseCategories.VIDEO.id,
  nameMsgId: 'enums.expertise_fields.visual_effects.name',
  descMsgId: 'enums.expertise_fields.visual_effects.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/video/fields/visual-effects/visual-effects@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/video/fields/visual-effects/visual-effects@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/video/fields/visual-effects/visual-effects@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/video/fields/visual-effects/visual-effects@1280x800.jpg',
  slug: 'visual-effects',
});

const values = ObjectFreeze([
  // Art Fields:
  GLASSWORK,
  GRAPHIC_ART,
  METALWORK,
  MULTIMEDIA_ART,
  SCULPTURE,
  WOODCRAFT,
  // Business Fields:
  ENTREPRENEURSHIP,
  FINANCE,
  INVESTING,
  MANAGEMENT,
  MARKETING,
  // Communication Fields:
  FICTION_WRITING,
  JOURNALISM,
  NONFICTION_WRITING,
  PUBLIC_RELATIONS,
  RADIO_BROADCASTING,
  TELEVISION_BROADCASTING,
  // Design Fields:
  ARCHITECTURAL_DESIGN,
  COMPUTER_AIDED_DESIGN,
  GRAPHIC_DESIGN,
  INTERIOR_DESIGN,
  PRODUCT_DESIGN,
  UI_UX_DESIGN,
  // Lifestyle Fields:
  CRAFTS,
  EVENT_PLANNING,
  FASHION_AND_BEAUTY,
  FOOD_AND_DRINK,
  HEALTH_AND_WELLNESS,
  RELIGION_AND_SPIRITUALITY,
  // Math and Science Fields:
  COMPUTER_SCIENCE,
  ENGINEERING,
  LIFE_SCIENCE,
  MATHEMATICS,
  PHYSICAL_SCIENCE,
  SOCIAL_SCIENCE,
  // Music Fields:
  AUDIO_ENGINEERING,
  LIVE_PERFORMANCE,
  MASTERING,
  MIXING,
  MUSIC_PRODUCTION,
  RECORDING,
  SOUND_DESIGN,
  // Performing Arts Fields:
  COMEDY,
  DANCE,
  JUGGLING,
  MAGIC,
  THEATER,
  // Photography Fields:
  AERIAL_PHOTOGRAPHY,
  ASTROPHOTOGRAPHY,
  COMMERCIAL_PHOTOGRAPHY,
  EVENT_PHOTOGRAPHY,
  NATURE_AND_WILDLIFE,
  PHOTO_EDITING,
  // Society Fields:
  EDUCATION,
  ENVIRONMENT_AND_SUSTAINABILITY,
  GOVERNMENT,
  LAW,
  NONPROFIT_ORGANIZATIONS,
  // Sports and Recreation Fields:
  ADVENTURE_SPORTS,
  EXTREME_SPORTS,
  FITNESS_AND_EXERCISE,
  MOTOR_SPORTS,
  SUMMER_SPORTS,
  WINTER_SPORTS,
  // Technology Fields:
  APP_DEVELOPMENT,
  ARTIFICIAL_INTELLIGENCE,
  GAME_DEVELOPMENT,
  NETWORK_AND_SECURITY,
  ROBOTICS,
  SOFTWARE_ENGINEERING,
  WEB_DEVELOPMENT,
  // Trade Skills Fields:
  AUTOMOTIVE,
  AVIATION,
  CONSTRUCTION,
  ELECTRICAL,
  HOME_IMPROVEMENT,
  MANUFACTURING,
  MECHANICAL,
  // Video Fields:
  ANIMATION,
  CGI_MODELING,
  POST_PRODUCTION,
  SCREENWRITING,
  VIDEO_EDITING,
  VIDEO_PRODUCTION,
  VISUAL_EFFECTS,
]);
// const ids = ObjectFreeze(values.map(field => field.id));
// const slugs = ObjectFreeze(values.map(field => field.slug));

const allValues = [
  ...values,
  ART_CATEGORY,
  BUSINESS_CATEGORY,
  COMMUNICATION_CATEGORY,
  DESIGN_CATEGORY,
  LIFESTYLE_CATEGORY,
  MATH_AND_SCIENCE_CATEGORY,
  MUSIC_CATEGORY,
  PERFORMING_ARTS_CATEGORY,
  PHOTOGRAPHY_CATEGORY,
  SOCIETY_CATEGORY,
  SPORTS_AND_RECREATION_CATEGORY,
  TECHNOLOGY_CATEGORY,
  TRADE_SKILLS_CATEGORY,
  VIDEO_CATEGORY,
];

const BY_CAT_ID_LUT = {};
values.forEach(field => {
  const key = field.expertiseCategoryId;
  const fields = BY_CAT_ID_LUT[key] || [];
  fields.push(field);
  BY_CAT_ID_LUT[key] = fields;
});
Object.values(BY_CAT_ID_LUT).forEach(fields => ObjectFreeze(fields));
ObjectFreeze(BY_CAT_ID_LUT);

function findByCatId(catId) {
  return BY_CAT_ID_LUT[catId];
}

const BY_ID_LUT = {};
allValues.forEach(field => {
  BY_ID_LUT[field.id] = field;
});
ObjectFreeze(BY_ID_LUT);

function findById(id) {
  return BY_ID_LUT[id];
}

const BY_SLUG_LUT = {};
allValues.forEach(field => {
  const slug = `${field.expertiseCategory.slug}:${field.slug}`;
  BY_SLUG_LUT[slug] = field;
});
ObjectFreeze(BY_SLUG_LUT);

function findBySlug(catSlug, fldSlug) {
  const slug = `${catSlug}:${fldSlug}`;
  return BY_SLUG_LUT[slug];
}

const ExpertiseFields = {
  // Art Fields:
  GLASSWORK,
  GRAPHIC_ART,
  METALWORK,
  MULTIMEDIA_ART,
  SCULPTURE,
  WOODCRAFT,
  // Business Fields:
  ENTREPRENEURSHIP,
  FINANCE,
  INVESTING,
  MANAGEMENT,
  MARKETING,
  // Communication Fields:
  FICTION_WRITING,
  JOURNALISM,
  NONFICTION_WRITING,
  PUBLIC_RELATIONS,
  RADIO_BROADCASTING,
  TELEVISION_BROADCASTING,
  // Design Fields:
  ARCHITECTURAL_DESIGN,
  COMPUTER_AIDED_DESIGN,
  GRAPHIC_DESIGN,
  INTERIOR_DESIGN,
  PRODUCT_DESIGN,
  UI_UX_DESIGN,
  // Lifestyle Fields:
  CRAFTS,
  EVENT_PLANNING,
  FASHION_AND_BEAUTY,
  FOOD_AND_DRINK,
  HEALTH_AND_WELLNESS,
  RELIGION_AND_SPIRITUALITY,
  // Math and Science Fields:
  COMPUTER_SCIENCE,
  ENGINEERING,
  LIFE_SCIENCE,
  MATHEMATICS,
  PHYSICAL_SCIENCE,
  SOCIAL_SCIENCE,
  // Music Fields:
  AUDIO_ENGINEERING,
  LIVE_PERFORMANCE,
  MASTERING,
  MIXING,
  MUSIC_PRODUCTION,
  RECORDING,
  SOUND_DESIGN,
  // Performing Arts Fields:
  COMEDY,
  DANCE,
  JUGGLING,
  MAGIC,
  THEATER,
  // Photography Fields:
  AERIAL_PHOTOGRAPHY,
  ASTROPHOTOGRAPHY,
  COMMERCIAL_PHOTOGRAPHY,
  EVENT_PHOTOGRAPHY,
  NATURE_AND_WILDLIFE,
  PHOTO_EDITING,
  // Society Fields:
  EDUCATION,
  ENVIRONMENT_AND_SUSTAINABILITY,
  GOVERNMENT,
  LAW,
  NONPROFIT_ORGANIZATIONS,
  // Sports and Recreation Fields:
  ADVENTURE_SPORTS,
  EXTREME_SPORTS,
  FITNESS_AND_EXERCISE,
  MOTOR_SPORTS,
  SUMMER_SPORTS,
  WINTER_SPORTS,
  // Technology Fields:
  APP_DEVELOPMENT,
  ARTIFICIAL_INTELLIGENCE,
  GAME_DEVELOPMENT,
  NETWORK_AND_SECURITY,
  ROBOTICS,
  SOFTWARE_ENGINEERING,
  WEB_DEVELOPMENT,
  // Trade Skills Fields:
  AUTOMOTIVE,
  AVIATION,
  CONSTRUCTION,
  ELECTRICAL,
  HOME_IMPROVEMENT,
  MANUFACTURING,
  MECHANICAL,
  // Video Fields:
  ANIMATION,
  CGI_MODELING,
  POST_PRODUCTION,
  SCREENWRITING,
  VIDEO_EDITING,
  VIDEO_PRODUCTION,
  VISUAL_EFFECTS,
  findByCatId,
  findById,
  findBySlug,
  values,
};

export default ExpertiseFields;
