// NOTE: The information in this file is used to drive the creation of profile
// photos at different sizes.
//
// **Resizing**
//
// The `width` property controls the width of the image. An `height` property
// may also be included to restrict the height too. If `width` is `null`, then
// no resizing of the original uploaded file will occur. However, a copy will be
// made.

const LARGE = Object.freeze({
  code: 'lg',
  descEn: 'Large',
  width: 1024,
});

const MEDIUM = Object.freeze({
  code: 'md',
  descEn: 'Medium',
  width: 512,
});

const ORIGINAL = Object.freeze({
  code: 'or',
  descEn: 'Original',
  width: null, // Don't resize.
});

const SMALL = Object.freeze({
  code: 'sm',
  descEn: 'Small',
  width: 256,
});

const THUMBNAIL = Object.freeze({
  code: 'th',
  descEn: 'Thumbnail',
  height: 128,
  width: 128,
});

const values = Object.freeze([LARGE, MEDIUM, ORIGINAL, SMALL, THUMBNAIL]);
const codes = Object.freeze(values.map(size => size.code));

const PPSIZES_BY_CODE_LUT = {};

values.forEach(ppSize => {
  PPSIZES_BY_CODE_LUT[ppSize.code] = ppSize;
});

function fromCode(code) {
  return PPSIZES_BY_CODE_LUT[code] || null;
}

const ProfilePhotoSizes = {
  LARGE,
  MEDIUM,
  ORIGINAL,
  SMALL,
  THUMBNAIL,
  codes,
  fromCode,
  values,
};

export default ProfilePhotoSizes;
