exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3YJyY, ._1KVS-, ._3fZ5d, .Docvr{overflow:auto}._1KVS-{-webkit-transition:500ms ease-in-out;transition:500ms ease-in-out}._3YJyY{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column}._3fZ5d{-ms-flex:1 0 auto;flex:1 0 auto;overflow:visible}.Docvr{background:#fff;border-top:1px solid var(--neutral--200);border-top:1px solid var(--neutral--200);z-index:1}", ""]);

// exports
exports.locals = {
	"page": "_3YJyY",
	"page__head": "_1KVS-",
	"page__body": "_3fZ5d",
	"page__foot": "Docvr"
};