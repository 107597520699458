import gql from 'graphql-tag';

const getAreasOfExpertiseQuery = gql`
  query getAreasOfExpertise {
    expertiseCategories {
      edges {
        expertCount
        favorite
        node {
          desc
          # descMsgId
          fieldsConnection {
            edges {
              expertCount
              favorite
              node {
                desc
                # descMsgId
                expertiseCategoryId
                id
                img {
                  alt
                  altMsgId
                  src
                  srcSet
                }
                name
                nameMsgId
                slug
              }
            }
            totalCount
          }
          id
          img {
            alt
            altMsgId
            src
            srcSet
          }
          name
          nameMsgId
          slug
        }
      }
      totalCount
    }
  }
`;

export default getAreasOfExpertiseQuery;
