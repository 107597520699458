import ApolloClientUtils from '../../lib/ApolloClientUtils';
import FavoriteTypes from '../../lib/FavoriteTypes';

import getAllFavoritesQuery from '../../queries/getAllFavoritesQuery';
import getPopularNearbyShadowingsQuery from '../../queries/getPopularNearbyShadowingsQuery';
import getShadowingProfileQuery from '../../queries/getShadowingProfileQuery';
import getTopRatedExpertsNearbyQuery from '../../queries/getTopRatedExpertsNearbyQuery';
import findExpertProfilesQuery from '../../queries/findExpertProfilesQuery';

const createUpdateGetAllFavoritesQueryCache = (type, favoriteId) => (
  cache,
  result,
) => {
  const { data } = result;
  const { toggleFavorite } = data;
  const { record: favoriteRecord } = toggleFavorite;

  const cacheEntry = ApolloClientUtils.readQuery(cache, {
    query: getAllFavoritesQuery,
  });

  if (cacheEntry) {
    let nextRecords = null;
    if (type === FavoriteTypes.EXPERT.code) {
      nextRecords = [...cacheEntry.getAllFavorites.records].map(record => {
        let nextRecord = record;

        if (record.type === type && record.expertId === favoriteId) {
          nextRecord = {
            ...record,
            value: favoriteRecord.value,
          };
        }

        return nextRecord;
      });
    } else if (type === FavoriteTypes.SHADOWING.code) {
      nextRecords = [...cacheEntry.getAllFavorites.records].map(record => {
        let nextRecord = record;

        if (record.type === type && record.expertProfileId === favoriteId) {
          nextRecord = {
            ...record,
            value: favoriteRecord.value,
          };
        }

        return nextRecord;
      });
    }

    if (nextRecords) {
      const nextGetAllFavorites = {
        ...cacheEntry.getAllFavorites,
        records: nextRecords,
      };
      cache.writeQuery({
        query: getAllFavoritesQuery,
        data: {
          getAllFavorites: nextGetAllFavorites,
        },
      });
    }
  }
};

const createUpdateGetTopRatedExpertsNearbyQueryCache = (
  favoriteId,
  variables = { limit: 4 },
) => (cache, result) => {
  const { data } = result;
  const { toggleFavorite } = data;
  const { record: favoriteRecord } = toggleFavorite;

  const cacheEntry = ApolloClientUtils.readQuery(cache, {
    query: getTopRatedExpertsNearbyQuery,
    variables,
  });

  if (cacheEntry) {
    const nextEdges = [...cacheEntry.findExperts.edges].map(edge => {
      let nextEdge = edge;

      if (edge.node.userId === favoriteId) {
        nextEdge = {
          ...edge,
          favorite: favoriteRecord.value,
        };
      }
      return nextEdge;
    });
    const nextFindExperts = {
      ...cacheEntry.findExperts,
      edges: nextEdges,
    };

    cache.writeQuery({
      query: getTopRatedExpertsNearbyQuery,
      variables,
      data: {
        findExperts: nextFindExperts,
      },
    });
  }
};

const updateFindExpertProfilesCacheEntry = (
  cacheEntry,
  favoriteId,
  favorite,
) => {
  const nextEdges = [...cacheEntry.findExpertProfiles.edges].map(edge => {
    let nextEdge = edge;

    if (edge.node.id === favoriteId) {
      nextEdge = {
        ...edge,
        favorite,
      };
    }
    return nextEdge;
  });
  const nextFindExpertProfiles = {
    ...cacheEntry.findExpertProfiles,
    edges: nextEdges,
  };

  const nextCacheEntry = {
    findExpertProfiles: nextFindExpertProfiles,
  };

  return nextCacheEntry;
};

const createUpdateGetPopularNearbyShadowingsQueryCache = (
  favoriteId,
  variables,
) => (cache, result) => {
  const { data } = result;
  const { toggleFavorite } = data;
  const { record: favoriteRecord } = toggleFavorite;

  const cacheEntry = ApolloClientUtils.readQuery(cache, {
    query: getPopularNearbyShadowingsQuery,
    variables,
  });

  if (cacheEntry) {
    const nextCacheEntry = updateFindExpertProfilesCacheEntry(
      cacheEntry,
      favoriteId,
      favoriteRecord.value,
    );

    cache.writeQuery({
      query: getPopularNearbyShadowingsQuery,
      variables,
      data: nextCacheEntry,
    });
  }
};

const createUpdateGetShadowingProfileQueryCache = (favoriteId, variables) => (
  cache,
  result,
) => {
  const { data } = result;
  const { toggleFavorite } = data;
  const { record: favoriteRecord } = toggleFavorite;

  const cacheEntry = ApolloClientUtils.readQuery(cache, {
    query: getShadowingProfileQuery,
    variables,
  });

  if (cacheEntry) {
    const nextCacheEntry = updateFindExpertProfilesCacheEntry(
      cacheEntry,
      favoriteId,
      favoriteRecord.value,
    );

    cache.writeQuery({
      query: getShadowingProfileQuery,
      variables,
      data: nextCacheEntry,
    });
  }
};

const createUpdateFindExpertProfilesQueryCache = (favoriteId, variables) => (
  cache,
  result,
) => {
  const { data } = result;
  const { toggleFavorite } = data;
  const { record: favoriteRecord } = toggleFavorite;

  const cacheEntry = ApolloClientUtils.readQuery(cache, {
    query: findExpertProfilesQuery,
    variables,
  });

  if (cacheEntry) {
    const nextCacheEntry = updateFindExpertProfilesCacheEntry(
      cacheEntry,
      favoriteId,
      favoriteRecord.value,
    );

    cache.writeQuery({
      query: findExpertProfilesQuery,
      variables,
      data: nextCacheEntry,
    });
  }
};

export const createToggleFavoriteMutationUpdateCallback = (
  type,
  favoriteId,
  variables,
) => {
  const callbacks = [];
  if (type === FavoriteTypes.SHADOWING.code) {
    callbacks.push(
      createUpdateGetPopularNearbyShadowingsQueryCache(favoriteId, variables),
    );
    callbacks.push(
      createUpdateGetShadowingProfileQueryCache(favoriteId, variables),
    );
    callbacks.push(
      createUpdateFindExpertProfilesQueryCache(favoriteId, variables),
    );
  } else if (type === FavoriteTypes.EXPERT.code) {
    callbacks.push(
      createUpdateGetTopRatedExpertsNearbyQueryCache(favoriteId, variables),
    );
  }
  callbacks.push(createUpdateGetAllFavoritesQueryCache(type, favoriteId));

  return (cache, result) => {
    for (let i = 0, iLen = callbacks.length; i < iLen; ++i) {
      const callback = callbacks[i];
      try {
        callback(cache, result);
      } catch (err) {
        console.error(err);
      }
    }
  };
};

export const removeUnfavoritedItems = graphqlClient => {
  const { cache } = graphqlClient;

  const cacheEntry = ApolloClientUtils.readQuery(cache, {
    query: getAllFavoritesQuery,
  });

  if (cacheEntry) {
    // Remove any "favorites" that have been unfavorited.
    const nextRecords = [...cacheEntry.getAllFavorites.records]
      .map(record => {
        let nextRecord = record;

        if (!record.value) {
          nextRecord = null;
        }

        return nextRecord;
      })
      .filter(Boolean);

    const nextGetAllFavorites = {
      ...cacheEntry.getAllFavorites,
      records: nextRecords,
    };
    cache.writeQuery({
      query: getAllFavoritesQuery,
      data: {
        getAllFavorites: nextGetAllFavorites,
      },
    });
  }
};
