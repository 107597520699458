exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3DB-0{--grid--padding-left-right: 10px;margin:var(--block--spacing--xl) 0 var(--block--spacing--sm) 0;margin:var(--block--spacing--xl) 0 var(--block--spacing--sm) 0}._3DB-0 a{color:var(--neutral--700);color:var(--neutral--700);-webkit-transition:var(--base--transition);transition:var(--base--transition);transition:var(--base--transition);font-size:14px}._3DB-0 a:hover{opacity:1}._39-a0{margin:0 0 8px 0 !important;margin:0 0 .5rem 0 !important}._2L-IP{font-weight:700;font-size:14px;color:var(--neutral--800);color:var(--neutral--800);display:inline-block;margin-bottom:6px;border-bottom:2px solid var(--primary--200);border-bottom:2px solid var(--primary--200)}._1Phlq{color:var(--neutral--900);color:var(--neutral--900);font-size:40px;font-size:2.5rem;line-height:1;font-family:\"WorkSans-Thin\",sans-serif;text-transform:uppercase;margin-top:30px;display:inline-block}._1RtDX{text-align:justify;margin-right:50px;text-transform:uppercase;font-size:14px}._3i0ee{margin:0 var(--block--spacing) 0 0;margin:0 var(--block--spacing) 0 0;color:var(--neutral--500);color:var(--neutral--500)}._2qHPW{margin:0 var(--block--spacing--xs);margin:0 var(--block--spacing--xs)}._2qHPW svg{font-size:18px;color:var(--neutral--500);color:var(--neutral--500)}._763KS{display:-ms-flexbox;display:flex;height:100%;-ms-flex-align:start;align-items:flex-start;margin-top:30px}._27Zvf{margin:var(--block--spacing--sm) 0;margin:var(--block--spacing--sm) 0}@media(min-width: 768px){._3i0ee{margin:1rem 0 0 0}}", ""]);

// exports
exports.locals = {
	"footer": "_3DB-0",
	"linkList": "_39-a0",
	"title": "_2L-IP",
	"brandName": "_1Phlq",
	"purpose": "_1RtDX",
	"copyright": "_3i0ee",
	"socialIcons": "_2qHPW",
	"footerNavSection": "_763KS",
	"bottomFooter": "_27Zvf"
};