import cx from 'classnames';
import PTs from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import React from 'react';

import s from './Row.scss';

const Row = ({ children }) => (
  <div className={cx(s.row, 'row')}>{children}</div>
);

Row.propTypes = {
  children: PTs.node,
};

Row.defaultProps = {
  children: null,
};

export default withStyles(s)(Row);
