exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1TFKM{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center}._1vydT{width:100%;display:inline-block;white-space:nowrap;padding:8px;font-size:16px;font-size:1rem;vertical-align:middle;text-align:center;background-color:rgba(0,0,0,0);border:none}._1vydT svg{margin-right:var(--block--spacing--xs);margin-right:var(--block--spacing--xs)}._2uV7f._2uV7f._2uV7f{color:#fff}.TCGig{position:absolute;top:80px;right:var(--block--spacing);right:var(--block--spacing);width:155px;padding:10px;background:#fff;border-radius:var(--base--border-radius);border-radius:var(--base--border-radius);-webkit-box-shadow:var(--base--box-shadow);box-shadow:var(--base--box-shadow);box-shadow:var(--base--box-shadow);-webkit-transition:.5s cubic-bezier(0.52, 0.02, 0.17, 1.41);transition:.5s cubic-bezier(0.52, 0.02, 0.17, 1.41)}.TCGig:hover{-webkit-box-shadow:var(--base--box-shadow--hover);box-shadow:var(--base--box-shadow--hover);box-shadow:var(--base--box-shadow--hover)}.TCGig ._1vydT{width:100%;color:var(--neutral--900);color:var(--neutral--900)}.TCGig ._1vydT ._3LwAG{float:right;-webkit-transition:var(--base--transition);transition:var(--base--transition);transition:var(--base--transition)}.TCGig ._1vydT:hover ._3LwAG{opacity:.8}.TCGig ._1vydT.active{color:var(--primary--400);color:var(--primary--400);font-weight:600}.TCGig ._1vydT svg{float:left}._1fZrv{display:inline-block;width:50px;height:50px;margin:0 0 0 10px;padding:0;border:0;background:rgba(0,0,0,0);background-size:cover;background-position:center;overflow:hidden}._1fZrv ._1Tya4{top:2px;color:var(--neutral--900);color:var(--neutral--900);font-size:32px;font-size:2rem;-webkit-transition:500ms ease-in-out;transition:500ms ease-in-out}@media(min-width: 800px){._3uUUZ{display:none}}@media(max-width: 800px){._1JgKL{display:none}}.page--home ._1vydT, .page--home ._14N7k{color:var(--neutral--900);color:var(--neutral--900)}", ""]);

// exports
exports.locals = {
	"mainNav": "_1TFKM",
	"link": "_1vydT",
	"altLook": "_2uV7f",
	"profileDropDown": "TCGig",
	"linkTxt": "_3LwAG",
	"dropDownButton": "_1fZrv",
	"faBarsIcon": "_1Tya4",
	"smViewPort": "_3uUUZ",
	"lgViewPort": "_1JgKL",
	"button": "_14N7k"
};