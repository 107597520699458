/**
 * Putting too many files in a directory and having too many subdirectories pose
 * possible performance issues. See https://stackoverflow.com/questions/466521/how-many-files-can-i-put-in-a-directory
 * for some info.
 *
 * The Digital Ocean droplet being used to host Shadower has an `ext4` file system
 * type. (Ran `df -Th` to get this info.) With this file system, the main issue to
 * avoid is having too many files/sub-directories in any given directory due to
 * performance issues from `ls`, FTP clients, et al.
 *
 * @param {string} fileId - The file ID composed of 32 hex digits.
 */
function toSubPath(fileId) {
  // This 2 level directory structure allows (16 ** 3 = 4096) ** 2 = 16.78 megas
  // of possible slots for files.
  const subPath = `${fileId.slice(0, 3)}/${fileId.slice(3, 6)}/`;
  return subPath;
}

/**
 * @param {string} fileId -
 * @param {string} fileTypeCode - One of 'jpeg', 'png'.
 * @param {ProfilePhotoSize} ppSize -
 */
function toRelativePath(fileId, fileTypeCode, ppSize) {
  let relativePath = null;
  if (fileId) {
    const subPath = toSubPath(fileId);
    let size = '';
    if (ppSize) {
      size = `.${ppSize.code}`;
    }
    relativePath = `${subPath}${fileId}${size}.${fileTypeCode}`;
  }
  return relativePath;
}

const ProfilePhotoUtils = {
  toRelativePath,
  toSubPath,
};

export default ProfilePhotoUtils;
