/**
 * Allows to get the cache entry for a specified query. If not found, returns
 * `null`, unlike `ApolloClient#cache.readQuery`.
 *
 * @param {Cache|ApolloClient} graphqlClient
 * @param {Object} readQueryArgs
 */
function readQuery(cache, readQueryArgs) {
  let cacheEntry = null;

  if (cache) {
    if (cache.cache) {
      // eslint-disable-next-line no-param-reassign
      cache = cache.cache;
    }

    try {
      cacheEntry = cache.readQuery(readQueryArgs);
    } catch (e) {
      // ignore
    }
  }
  return cacheEntry;
}

const ApolloClientUtils = {
  readQuery,
};

export default ApolloClientUtils;
