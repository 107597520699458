import React from 'react';

import appContextTypes from '../../lib/appContextTypes';

function getDisplayName(Comp) {
  const compName = Comp.displayName || Comp.name || 'Component';
  return `InjectAppContext(${compName})`;
}

export default function injectAppContext(WrappedComponent) {
  class InjectAppContext extends React.PureComponent {
    static displayName = getDisplayName(WrappedComponent);

    static contextTypes = appContextTypes;

    static WrappedComponent = WrappedComponent;

    render() {
      return <WrappedComponent {...this.props} appContext={this.context} />;
    }
  }

  return InjectAppContext;
}
