exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".react-select__control.react-select__control--is-focused{border-color:var(--form--focus--border-color);border-color:var(--form--focus--border-color);-webkit-box-shadow:var(--form--focus--box-shadow);box-shadow:var(--form--focus--box-shadow);box-shadow:var(--form--focus--box-shadow)}.react-select__placeholder{color:var(--form--placeholder--color);color:var(--form--placeholder--color)}.react-select__control--is-focused .react-select__placeholder{opacity:var(--form--placeholder--focus--opacity);opacity:var(--form--placeholder--focus--opacity)}.react-select__option{cursor:pointer !important}.react-select__option:hover, .react-select__option.react-select__option--is-focused{background:var(--neutral--100) !important;background:var(--neutral--100) !important;color:var(--primary--400) !important;color:var(--primary--400) !important}.react-select__single-value{color:var(--base--color--light) !important;color:var(--base--color--light) !important}._3UYaz{color:var(--neutral--900);color:var(--neutral--900)}._3UYaz .react-select__control{-webkit-transition:500ms ease-in-out;transition:500ms ease-in-out;border:1px solid var(--neutral--300);border:1px solid var(--neutral--300);border-radius:30px;font-size:16px}._3UYaz .react-select__option__label{padding-left:16px;padding-left:1rem}._3UYaz .react-select__option__label.is-category{padding-left:0;font-weight:700}._3UYaz .react-select__single-value .react-select__option__label{padding-left:0;font-weight:inherit}._3-_fV .react-select__control{border-radius:0}", ""]);

// exports
exports.locals = {
	"root": "_3UYaz",
	"searchHidden": "_3-_fV"
};