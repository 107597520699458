exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".page__head.page__head{overflow:visible}.page__head.page__head .grid{overflow:visible}._3BDYN{min-height:100vh}@media(min-width: 576px){._2c-KS{padding:0 var(--block--spacing--sm);padding:0 var(--block--spacing--sm)}}._2c-KS{position:fixed;width:100%;padding:0;z-index:1}._343JO{z-index:0;padding-top:70px}", ""]);

// exports
exports.locals = {
	"page": "_3BDYN",
	"page__head": "_2c-KS",
	"page__body": "_343JO"
};