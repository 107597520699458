// Note: These photo types are being used to control what file formats are
// accepted when a profile photo is uploaded. If you make changes here, then be
// sure to check compatibility with the code in the `processUploadedProfilePhoto`
// function on the server-side.

const JPEG = Object.freeze({
  code: 'jpeg',
});

const PNG = Object.freeze({
  code: 'png',
});

const values = Object.freeze([JPEG, PNG]);
const codes = Object.freeze(values.map(type => type.code));

const ProfilePhotoTypes = { codes, JPEG, PNG, values };

export default ProfilePhotoTypes;
