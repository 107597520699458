exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._17vaq{padding-right:var(--grid--padding-left-right);padding-right:var(--grid--padding-left-right);padding-left:var(--grid--padding-left-right);padding-left:var(--grid--padding-left-right);margin-right:auto;margin-left:auto;width:100%;max-width:var(--max-content-width);max-width:var(--max-content-width);contain:layout}@media(min-width: 576px){._17vaq._1Ta6h{max-width:540px}}@media(min-width: 768px){._17vaq._1Ta6h{max-width:720px}}@media(min-width: 992px){._17vaq._1Ta6h{max-width:960px}}@media(min-width: 1200px){._17vaq._1Ta6h{max-width:1140px}}", ""]);

// exports
exports.locals = {
	"grid": "_17vaq",
	"snaps": "_1Ta6h"
};