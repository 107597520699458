import withStyles from 'isomorphic-style-loader/lib/withStyles';
import React from 'react';
import {
  faFacebookF,
  faInstagram,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Link from '../../components/Link';
import { Col, Row } from '../../incubation/components/ResponsiveGridLayout';

import s from './SiteFooter.scss';

// const purpose = `
// Shadower allows users to gain real-world experience by connecting them with
// experts and professionals across a wide range of jobs, skills, and passions.
// `;

const purpose = `
Skill shadowing in real life
`;

class SiteFooter extends React.Component {
  // eslint-disable-next-line class-methods-use-this
  renderShadowerCol(colProps) {
    return (
      <Col {...colProps}>
        <span className={s.brandName}>Shadower</span>
        <p className={s.purpose}>{purpose}</p>
      </Col>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderAboutCol(colProps) {
    return (
      <Col {...colProps} className={s.footerNavSection}>
        <div className={s.footerNavSection}>
          <div>
            <span className={s.title}>About</span>
            <ul className={s.linkList} role="navigation">
              <li>
                <Link to="/about">How it Works</Link>
              </li>
              <li>
                <Link to="/about/team">The Team</Link>
              </li>
              <li>
                <Link to="/blog">Our Blog</Link>
              </li>
              {/* <li>
                <Link to="about/careers/">Careers</Link>
              </li>*/}
            </ul>
          </div>
        </div>
      </Col>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderSupportCol(colProps) {
    return (
      <Col {...colProps}>
        <div className={s.footerNavSection}>
          <div>
            <span className={s.title}>Support</span>
            <ul className={s.linkList} role="navigation">
              <li>
                <Link to="/help">Help</Link>
              </li>
              <li>
                <Link to="/trust-and-safety">Safety</Link>
              </li>
              {/*  <li>
              <Link to="contact/">Contact</Link>
            </li> */}
              <li>
                <Link to="/handbook">Handbook</Link>
              </li>
            </ul>
          </div>
        </div>
      </Col>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderPoliciesCol(colProps) {
    return (
      <Col {...colProps}>
        <div className={s.footerNavSection}>
          <div>
            <span className={s.title}>Policies</span>
            <ul className={s.linkList} role="navigation">
              <li>
                <Link to="/policies/terms-of-service">Terms</Link>
              </li>
              <li>
                <Link to="/policies/privacy-policy">Privacy</Link>
              </li>

              <li>
                <Link to="/policies/shadowing-waiver">Waiver</Link>
              </li>
            </ul>
          </div>
        </div>
      </Col>
    );
  }

  render() {
    return (
      <footer className={s.footer}>
        {/* Note: We're expecting that this footer is being wrapped by a Grid component. */}
        <Row>
          {this.renderShadowerCol({ xs: 12, md: 4 })}
          {this.renderAboutCol({ xs: 6, sm: 3, md: 2 })}
          {this.renderSupportCol({ xs: 6, sm: 3, md: 2 })}
          {this.renderPoliciesCol({ xs: 6, sm: 3, md: 2 })}
        </Row>
        <div className={s.bottomFooter}>
          <span className={s.copyright}>© Shadower 2019</span>
          <span className={s.socialIcons}>
            <a
              href="https://instagram.com/shadowerapp"
              rel="noopener noreferrer"
              target="_blank"
            >
              <FontAwesomeIcon icon={faInstagram} />
            </a>
          </span>
          <span className={s.socialIcons}>
            <a
              href="https://twitter.com/shadowerapp"
              rel="noopener noreferrer"
              target="_blank"
            >
              <FontAwesomeIcon icon={faTwitter} />
            </a>
          </span>
          <span className={s.socialIcons}>
            <a
              href="https://facebook.com/shadowerapp"
              rel="noopener noreferrer"
              target="_blank"
            >
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
          </span>
        </div>
      </footer>
    );
  }
}

export default withStyles(s)(SiteFooter);
