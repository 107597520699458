import PTs from 'prop-types';

const ImgShape = PTs.shape({
  alt: PTs.string.isRequired,
  altMsgId: PTs.string,
  src: PTs.string,
  srcSet: PTs.string,
});

export default ImgShape;
