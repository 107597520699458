const CATEGORY = Object.freeze({
  // id: '1',
  code: 'cat',
  descEn: 'Favorite expertise categories.',
  nameEn: 'Expertise Category',
  nameMsgId: 'enums.favorite_types.category.name',
  descMsgId: 'enums.favorite_types.category.desc',
});

const EXPERT = Object.freeze({
  // id: '2',
  code: 'exp',
  descEn: 'Favorite expert.',
  nameEn: 'Expert',
  nameMsgId: 'enums.favorite_types.expert.name',
  descMsgId: 'enums.favorite_types.expert.desc',
});

const LOCATION = Object.freeze({
  // id: '3',
  code: 'loc',
  descEn: 'Favorite location.',
  nameEn: 'Location',
  nameMsgId: 'enums.favorite_types.location.name',
  descMsgId: 'enums.favorite_types.location.desc',
});

const SHADOWING = Object.freeze({
  // id: '4',
  code: 'shd',
  descEn: 'Favorite shadowing opportunity.',
  nameEn: 'Shadowing',
  nameMsgId: 'enums.favorite_types.shadowing.name',
  descMsgId: 'enums.favorite_types.shadowing.desc',
});

const values = Object.freeze([CATEGORY, EXPERT, LOCATION, SHADOWING]);
const codes = Object.freeze(values.map(type => type.code));
// const ids = Object.freeze(values.map(type => type.id));

// const BY_CODE_LUT = {};
// values.forEach(type => {
//   BY_CODE_LUT[type.code] = type;
// });
// Object.freeze(BY_CODE_LUT);

// function findByCode(code) {
//   return BY_CODE_LUT[code];
// }

const FavoriteTypes = {
  CATEGORY,
  EXPERT,
  LOCATION,
  SHADOWING,
  codes,
  // findByCode,
  values,
};

export default FavoriteTypes;
