import cx from 'classnames';

import withStyles from 'isomorphic-style-loader/lib/withStyles';
import PTs from 'prop-types';
import React from 'react';

import {
  faSearch
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import history from '../../../client/history';

import Link from '../Link';
import Navigation from '../Navigation';
import CategoryPicker from '../CategoryPicker';

import s from './SiteHeader.scss';

class SiteHeader extends React.Component {
  static propTypes = {
    className: PTs.string,
    isPageScrolledToTop: PTs.bool.isRequired,
    look: PTs.oneOf(['default', 'secondary']),
  };

  static defaultProps = {
    className: '',
    look: 'default',
  };

  constructor(props) {
    // console.log('SiteHeader#constructor');
    super(props);

    this.state = {
      isSearchOpen: false,
    };

    this.searchDrawerCtRef = React.createRef();
  }

  handleTransition = evt => {
    // console.log('SiteHeader#handleTransition');
    const { type } = evt;
    const { isSearchOpen } = this.state;

    if (type === 'transitionend') {
      if (isSearchOpen) {
        this.searchDrawerCtRef.current.classList.add(
          s.searchDrawerIsFullyOpened,
        );
      }
    }
  };

  handleCategoryPickerEnter = inputValue => {
    if (inputValue) {
      history.push(`shadowings/areas-of-expertise/${inputValue}/`);
    }
  };

  handleCategoryPickerSelect = option => {
    if (option.fldId === null) {
      history.push(`shadowings/areas-of-expertise/${option.catSlug}/`);
    } else {
      history.push(
        `shadowings/areas-of-expertise/${option.catSlug}/${option.fldSlug}/`,
      );
    }
  };

  toggleSearchBarState = () => {
    this.setState(state => {
      const nextIsSearchOpen = !state.isSearchOpen;
      if (!nextIsSearchOpen) {
        this.searchDrawerCtRef.current.classList.remove(
          s.searchDrawerIsFullyOpened,
        );
      }
      return {
        isSearchOpen: nextIsSearchOpen,
      };
    });
  };

  render() {
    // console.log('SiteHeader#render');
    const { className, isPageScrolledToTop, look } = this.props;
    const { isSearchOpen } = this.state;
    const headerLook = look === 'secondary' ? s.headerLookSecondary : '';
    return (
      <header
        className={cx(className, s.header, headerLook, {
          [s.isPageScrolledToTop]: isPageScrolledToTop,
          [s.isSearchOpen]: isSearchOpen,
        })}
      >
        <div className={s.logoContainer}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link className={s.logoLink} to="/">
            <svg
              className={s.logo}
              clipRule="evenodd"
              fillRule="evenodd"
              strokeLinejoin="round"
              strokeMiterlimit="1.414"
              viewBox="0 0 1309 1309"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                // eslint-disable-next-line max-len
                d="M98.877,440.435c0.003,-234.981 251.368,-425.752 560.979,-425.752c308.525,0 558.878,189.026 560.965,422.806l0.014,425.752l-0.014,-0.013c0.009,0.986 0.014,1.972 0.014,2.959c0,234.979 -251.367,425.752 -560.979,425.752c-309.819,0 -560.977,-190.614 -560.979,-425.752c0,0 0,0 0,0c0,0 0,-425.752 0,-425.752l0,0Zm981.642,141.128l-1.396,-140.779c0,-175.737 -187.713,-318.549 -419.267,-318.549c-231.4,0 -419.267,142.58 -419.267,318.2c0,175.736 187.689,318.51 419.246,318.512l0.021,-105.636c154.91,0 280.489,95.308 280.489,212.876c0,117.489 -125.683,212.876 -280.489,212.876c-154.908,0 -280.487,-95.306 -280.489,-212.876l0,0c0,-27.436 0,-56.958 0,-56.958l-0.013,-0.008c-52.748,-23.158 -100.066,-52.648 -140.15,-87.102l-0.011,-0.007c-0.513,10.497 0.131,134.962 0.144,148.037c0.003,175.739 188.966,314.238 420.519,314.238c231.399,0 419.267,-142.581 419.267,-318.2c0,-175.736 -187.71,-318.199 -419.267,-318.2l0,105.324c-154.91,0 -280.489,-95.308 -280.489,-212.876c0,-117.49 125.683,-212.876 280.489,-212.876c153.614,0 278.386,93.719 280.462,209.93l0.027,56.957l18.068,11.23c39.539,18.899 75.751,41.442 107.817,67.007l14.289,8.88Zm-420.684,177.384c0,0 0,0.001 0,0.002c0.006,29.1 -12.728,56.743 -34.848,75.651c-22.119,18.908 -51.408,27.185 -80.152,22.651c-8.691,-1.397 -17.312,-2.925 -25.853,-4.602c0.089,5.24 0.149,10.053 0.149,13.538c0.002,58.987 63.006,106.803 140.725,106.803c77.668,0 140.725,-47.857 140.725,-106.803c0,-58.985 -63.023,-107.239 -140.746,-107.24Zm140.948,-304.964c-0.092,-4.979 -0.202,-13.548 -0.202,-13.548c0,-58.986 -63.005,-106.803 -140.725,-106.803c-77.669,0 -140.725,47.857 -140.725,106.803c0,58.985 63.003,106.801 140.725,106.803c0,0 0,-0.001 0,-0.002c0,-28.978 12.678,-56.506 34.7,-75.343c22.022,-18.836 51.183,-27.094 79.811,-22.602c8.882,1.42 17.69,2.978 26.416,4.692Z"
              />
            </svg>
            <span className={s.companyName}>Shadower</span>

            <p className={s.beta}>Beta</p>
          </Link>
        </div>

        {/*    <button
          type="button"
          className={s.searchButton}
          onClick={this.toggleSearchBarState}
        > */}
        <div ref={this.searchDrawerCtRef} className={s.searchContainer}>
          <div
            className={s.searchDrawer}
            onTransitionEnd={this.handleTransition}
          >
            <CategoryPicker
              className={cx(s.categoryPicker)}
              isSearchOpen={isSearchOpen}
              onEnter={this.handleCategoryPickerEnter}
              onSelect={this.handleCategoryPickerSelect}
            />
          </div>

          <button
            className={s.searchButton}
            onClick={this.toggleSearchBarState}
            type="button"
          >
            <FontAwesomeIcon className={s.searchIcon} icon={faSearch} />
          </button>
        </div>
        {/*  </button> */}
        <Navigation isPageScrolledToTop={isPageScrolledToTop} look={look} />
      </header>
    );
  }
}

export default withStyles(s)(SiteHeader);
