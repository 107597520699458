import gql from 'graphql-tag';

const toggleFavoriteMutation = gql`
  mutation toggleFavorite(
    $favoriteId: ID!
    $newValue: Boolean!
    $type: FavoriteTypeCode!
  ) {
    toggleFavorite(favoriteId: $favoriteId, newValue: $newValue, type: $type) {
      record {
        userId
        expertId
        expertProfileId
        type
        value
      }
    }
  }
`;

export default toggleFavoriteMutation;
