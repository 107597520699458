import gql from 'graphql-tag';

const getAllFavoritesQuery = gql`
  query getAllFavorites {
    getAllFavorites {
      records {
        expertId
        expertProfileId
        type
        value
        expert {
          displayName
          firstName
          lastName
          profilePhoto {
            alt
            src
          }
          profilePhotoId
          profilePhotoTypeCode
          slug
          userId
        }
        expertProfile {
          id
          name
          slug
          expert {
            displayName
            firstName
            lastName
            profilePhoto {
              alt
              src
            }
            profilePhotoId
            profilePhotoTypeCode
            slug
            userId
          }
        }
      }
    }
  }
`;

export default getAllFavoritesQuery;
