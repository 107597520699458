import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import PTs from 'prop-types';
import React from 'react';

import config from '../../config/client-config';

import ProfilePhotoSizes from '../../lib/ProfilePhotoSizes';
import ProfilePhotoTypeCodeShape from '../../lib/ProfilePhotoTypeCodeShape';
import ProfilePhotoUtils from '../../lib/ProfilePhotoUtils';

import s from './ProfilePhoto.scss';

const { userProfilePhotoRoot } = config.app;

class ProfilePhoto extends React.Component {
  static propTypes = {
    alt: PTs.string,
    className: PTs.string,
    height: PTs.number,
    imgClassName: PTs.string,
    shape: PTs.oneOf(['circle', 'square']),
    userProfile: PTs.shape({
      displayName: PTs.string.isRequired,
      firstName: PTs.string.isRequired,
      lastName: PTs.string.isRequired,
      profilePhotoId: PTs.string,
      profilePhotoTypeCode: ProfilePhotoTypeCodeShape,
    }).isRequired,
    width: PTs.number,
  };

  static defaultProps = {
    alt: null,
    className: '',
    height: null,
    imgClassName: '',
    shape: 'circle',
    width: null,
  };

  componentDidMount() {
    this.flexFont();
    window.addEventListener('resize', this.flexFont);
  }

  componentDidUpdate() {
    this.flexFont();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.flexFont);
  }

  flexFont = () => {
    const divs = document.getElementsByClassName('flexFont');
    for (let i = 0; i < divs.length; i++) {
      const relFontsize = divs[i].offsetWidth * 0.5;
      divs[i].style.fontSize = `${relFontsize}px`;
      divs[i].style.lineHeight = `${divs[i].offsetWidth}px`;
    }
  };

  render() {
    const {
      alt,
      className,
      height,
      imgClassName,
      shape,
      userProfile,
      width,
      ...imgProps
    } = this.props;

    const altText = alt || userProfile.displayName;
    const initials = userProfile.firstName[0] + userProfile.lastName[0];

    let ppSize;
    const maxDim = Math.max(width, height);
    if (maxDim <= ProfilePhotoSizes.THUMBNAIL.width) {
      ppSize = ProfilePhotoSizes.THUMBNAIL;
    } else if (maxDim <= ProfilePhotoSizes.SMALL.width) {
      ppSize = ProfilePhotoSizes.SMALL;
    } else if (maxDim <= ProfilePhotoSizes.MEDIUM.width) {
      ppSize = ProfilePhotoSizes.MEDIUM;
    } else if (maxDim <= ProfilePhotoSizes.LARGE.width) {
      ppSize = ProfilePhotoSizes.LARGE;
    } else {
      ppSize = ProfilePhotoSizes.ORIGINAL;
    }
    const fileId = userProfile.profilePhotoId;
    const fileTypeCode = userProfile.profilePhotoTypeCode;
    const relPath = ProfilePhotoUtils.toRelativePath(
      fileId,
      fileTypeCode,
      ppSize,
    );
    const source = relPath && `${userProfilePhotoRoot}${relPath}`;

    return (
      <div
        className={cx(className, s.profilePhoto, {
          [s.circle]: shape === 'circle',
          [s.square]: shape === 'square',
        })}
        style={{ width: `${width}px` }}
      >
        {source ? (
          <img
            alt={altText}
            className={imgClassName}
            height={height}
            src={source}
            width={width}
            {...imgProps}
          />
        ) : (
          <div
            className={s.default}
            style={{ width: `${width}px`, height: `${height || '0'}px` }}
          >
            <p
              className="flexFont"
              style={{ fontSize: `${width / 2}px`, lineHeight: `${width}px` }}
            >
              {initials}
            </p>
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(s)(ProfilePhoto);
