import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import PTs from 'prop-types';
import React from 'react';

import {
  faBars,
  faInfoCircle,
  faSignInAlt,
  faUserPlus,
} from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Link from '../Link';

import s from './NavigationBeforeLogin.scss';

class NavigationBeforeLogin extends React.Component {
  static propTypes = {
    className: PTs.string,
    displayProfileDropDown: PTs.bool,
    isPageScrolledToTop: PTs.bool.isRequired,
    look: PTs.string.isRequired,
    openLoginModal: PTs.func.isRequired,
    openSignupModal: PTs.func.isRequired,
    setProp: PTs.func.isRequired,
  };

  static defaultProps = {
    className: '',
    displayProfileDropDown: false,
  };

  componentWillMount() {
    if (process.env.BROWSER) {
      document.addEventListener('click', this.handleDocClick, false);
    }
  }

  componentWillUnmount() {
    if (process.env.BROWSER) {
      document.removeEventListener('click', this.handleDocClick);
    }
  }

  handleClickProfile = () => {
    const { displayProfileDropDown, setProp } = this.props;
    setProp('displayProfileDropDown', !displayProfileDropDown);
  };

  handleDocClick = e => {
    const { displayProfileDropDown } = this.props;
    if (this.node.contains(e.target)) {
      return;
    }

    if (displayProfileDropDown) {
      this.handleClickProfile();
    }
  };

  hideDropDown = () => {
    const { setProp } = this.props;
    setProp('displayProfileDropDown', false);
  };

  openLoginModal = () => {
    this.props.openLoginModal();
    this.hideDropDown();
  };

  openSignupModal = () => {
    this.props.openSignupModal();
    this.hideDropDown();
  };

  render() {
    const {
      className,
      displayProfileDropDown,
      isPageScrolledToTop,
      look,
    } = this.props;
    return (
      <div>
        {/* Small viewport nav */}
        <nav
          className={cx(
            className,
            s.mainNav,
            s.smViewPort,
            '-e2e-viewport-small',
          )}
          /* eslint-disable-next-line */
          ref={node => (this.node = node)}
        >
          <button
            className={s.dropDownButton}
            onClick={this.handleClickProfile}
            type="button"
          >
            <FontAwesomeIcon
              className={
                look === 'secondary' && isPageScrolledToTop
                  ? cx(s.faBarsIcon, s.altLook)
                  : s.faBarsIcon
              }
              icon={faBars}
            />
          </button>

          <div
            className={cx('profile_dropdown', s.profileDropDown)}
            style={
              displayProfileDropDown ? { right: '16px' } : { right: '-260px' }
            }
          >
            {look === 'secondary' && (
              <Link className={s.link} onClick={this.hideDropDown} to="/about">
                <FontAwesomeIcon icon={faInfoCircle} />
                <span className={s.linkTxt}>About</span>
              </Link>
            )}
            <button
              className={cx(s.link, '-e2e-login-link')}
              onClick={this.openLoginModal}
              type="button"
            >
              <FontAwesomeIcon icon={faSignInAlt} />
              <span className={s.linkTxt}>Log in</span>
            </button>
            <button
              className={s.link}
              onClick={this.openSignupModal}
              type="button"
            >
              <FontAwesomeIcon icon={faUserPlus} />
              <span className={s.linkTxt}>Sign up</span>
            </button>
          </div>
        </nav>
        {/* Large viewport nav */}
        <nav
          className={cx(
            className,
            s.mainNav,
            s.lgViewPort,
            '-e2e-viewport-large',
          )}
        >
          {look === 'secondary' && (
            <Link
              className={isPageScrolledToTop ? cx(s.link, s.altLook) : s.link}
              onClick={this.hideDropDown}
              to="/about"
            >
              About
            </Link>
          )}
          <button
            className={
              look === 'secondary' && isPageScrolledToTop
                ? cx(s.link, s.altLook, '-e2e-login-link')
                : cx(s.link, '-e2e-login-link')
            }
            onClick={this.openLoginModal}
            type="button"
          >
            Log in
          </button>
          <button
            className={
              look === 'secondary' && isPageScrolledToTop
                ? cx(s.link, s.altLook)
                : s.link
            }
            onClick={this.openSignupModal}
            type="button"
          >
            Sign up
          </button>
        </nav>
      </div>
    );
  }
}

export default withStyles(s)(NavigationBeforeLogin);
