import gql from 'graphql-tag';
import { Log } from 'qc-log_api';

import fetch from '../../lib/core/fetch';

import { is401Error } from '../../lib/ApolloErrorUtils';
import { normalizeRecoverableErrors } from '../../lib/GraphQlUtils';

import changePasswordMutation from '../../queries/changePasswordMutation';
import logoutMutation from '../../queries/logoutMutation';
import toggleFavoriteMutation from '../../queries/toggleFavoriteMutation';

import { createToggleFavoriteMutationUpdateCallback } from '../apollo/toggleFavoriteMutationCallbacks';

import { loadAccount } from './AccountActions';

import {
  normalizeActionResult,
  pushUnauthenticatedError,
  pushUnexpectedError,
} from './lib/ActionUtils';

import { openLoginModal } from './modals';

import { USER_LOGOUT_SUCCESS } from './';

const ACTIONS_LOG = Log.Factory.get('users.authenticated.actions');

function changePassword(oldPassword, newPassword, confirmPassword) {
  /**
   * @returns {Promise<ActionResult, undefined>}
   */
  return async (dispatch, getState, { graphqlClient }) => {
    const actionResult = {
      status: 'failed',
    };

    try {
      const variables = { confirmPassword, newPassword, oldPassword };
      const { data } = await graphqlClient.mutate({
        mutation: changePasswordMutation,
        variables,
      });
      if (data) {
        const result = data.changePassword;
        actionResult.errors = normalizeRecoverableErrors(result.errors);
        actionResult.status = result.status;
      }
    } catch (e) {
      if (is401Error(e)) {
        pushUnauthenticatedError(actionResult);
      } else {
        ACTIONS_LOG.error(e);
        pushUnexpectedError(actionResult);
      }
    }

    normalizeActionResult(actionResult);

    return actionResult;
  };
}

function logout() {
  /**
   * @returns {Promise<ActionResult, undefined>}
   */
  return async (dispatch, getState, { graphqlClient }) => {
    const actionResult = {
      status: 'failed',
    };

    try {
      const { data } = await graphqlClient.mutate({
        mutation: logoutMutation,
      });
      if (data) {
        actionResult.status = data.logout.status;
      }
    } catch (e) {
      ACTIONS_LOG.error(e);
      pushUnexpectedError(actionResult);
    }

    normalizeActionResult(actionResult);

    if (actionResult.status === 'success') {
      // Resetting the Apollo store causes the current page's queries to be rerun
      // under the condition of being logged out. This can cause a flash of errors
      // before the page is redirected. Since we are redirecting when logging out,
      // we won't reset the store.
      // await graphqlClient.resetStore();
      dispatch({
        type: USER_LOGOUT_SUCCESS,
      });
    }

    return actionResult;
  };
}

async function doRemoveProfilePhoto() {
  try {
    const resp = await fetch('/api/user/profile/photo', {
      method: 'delete',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    });
    await resp.json();
  } catch (error) {
    console.error(error);
  }
}

function removeProfilePhoto() {
  return async dispatch => {
    await doRemoveProfilePhoto();
    await dispatch(loadAccount());
  };
}

const unlinkPayPalMutation = gql`
  mutation {
    unlinkPayPal {
      errors {
        key
        value {
          code
          msgId
          valuesJson
        }
      }
      status
    }
  }
`;

function unlinkPayPal() {
  /**
   * @returns {Promise<ActionResult, undefined>}
   */
  return async (dispatch, getState, { graphqlClient }) => {
    const actionResult = {
      errors: [],
      status: 'failed',
    };

    try {
      const { data } = await graphqlClient.mutate({
        mutation: unlinkPayPalMutation,
      });
      if (data) {
        const result = data.unlinkPayPal;
        actionResult.errors = actionResult.errors.concat(
          normalizeRecoverableErrors(result.errors) || [],
        );
        actionResult.status = result.status;
      }
    } catch (e) {
      if (is401Error(e)) {
        pushUnauthenticatedError(actionResult);
      } else {
        ACTIONS_LOG.error(e);
        pushUnexpectedError(actionResult);
      }
    }

    normalizeActionResult(actionResult);

    return actionResult;
  };
}

const unlinkStripeMutation = gql`
  mutation {
    unlinkStripe {
      errors {
        key
        value {
          code
          msgId
          valuesJson
        }
      }
      status
    }
  }
`;

function unlinkStripe() {
  /**
   * @returns {Promise<ActionResult, undefined>}
   */
  return async (dispatch, getState, { graphqlClient }) => {
    const actionResult = {
      errors: [],
      status: 'failed',
    };

    try {
      const { data } = await graphqlClient.mutate({
        mutation: unlinkStripeMutation,
      });
      if (data) {
        const result = data.unlinkStripe;
        actionResult.errors = actionResult.errors.concat(
          normalizeRecoverableErrors(result.errors) || [],
        );
        actionResult.status = result.status;
      }
    } catch (e) {
      if (is401Error(e)) {
        pushUnauthenticatedError(actionResult);
      } else {
        ACTIONS_LOG.error(e);
        pushUnexpectedError(actionResult);
      }
    }

    normalizeActionResult(actionResult);

    return actionResult;
  };
}

function updateFavorite(type, data, variables, loginModalMessage = null) {
  return async (dispatch, getState_unused, { graphqlClient }) => {
    const { favoriteId, newValue } = data;

    const actionResult = {
      errors: [],
      status: 'failed',
    };

    try {
      // TODO: Update toggleFavoriteMutation to return errors and status.
      /* const mutateResult =*/ await graphqlClient.mutate({
        mutation: toggleFavoriteMutation,
        update: createToggleFavoriteMutationUpdateCallback(
          type,
          favoriteId,
          variables,
        ),
        variables: {
          favoriteId,
          newValue,
          type,
        },
      });
      actionResult.status = 'success'; // Assume mutation succeeded until status is returned.
      // const { data, errors } = mutateResult;
      // actionResult.errors.concat(errors, data.updateUserAccount.errors);
      // actionResult.status = data.updateUserAccount.status;
    } catch (error) {
      if (error.networkError && error.networkError.statusCode === 401) {
        dispatch(
          openLoginModal({
            message: loginModalMessage,
          }),
        );
      } else {
        actionResult.errors.push(error);
      }
    }

    return actionResult;
  };
}

const updateLoggedInUserMutation = gql`
  mutation updateLoggedInUser($input: UpdateUserAccountInput!) {
    updateUserAccount(input: $input) {
      errors {
        key
        value {
          code
          msgId
          valuesJson
        }
      }
      status
    }
  }
`;

/**
 * @param {Object} userInfo
 * @param {string} userInfo.aboutYou
 * @param {Object} userInfo.address
 * @param {string} userInfo.address.country
 * @param {string} userInfo.address.formatted
 * @param {string} userInfo.address.locality
 * @param {string} userInfo.address.region
 * @param {string} userInfo.address.postalCode
 * @param {string} userInfo.address.streetName
 * @param {string} userInfo.address.streetNumber
 * @param {number} userInfo.lat
 * @param {number} userInfo.lng
 */
function updateLoggedInUser(userInfo) {
  const { aboutYou, address, lat, lng } = userInfo;
  /**
   * @returns {Promise<ActionResult, undefined>}
   */
  return async (dispatch, getState, { graphqlClient }) => {
    const actionResult = {
      status: 'failed',
    };

    try {
      const { data } = await graphqlClient.mutate({
        mutation: updateLoggedInUserMutation,
        variables: {
          input: {
            aboutYou,
            address,
            lat,
            lng,
          },
        },
      });
      if (data) {
        const result = data.updateUserAccount;
        actionResult.errors = normalizeRecoverableErrors(result.errors);
        actionResult.status = data.updateUserAccount.status;
      }
    } catch (e) {
      if (is401Error(e)) {
        pushUnauthenticatedError(actionResult);
      } else {
        ACTIONS_LOG.error(e);
        pushUnexpectedError(actionResult);
      }
    }

    normalizeActionResult(actionResult);

    return actionResult;
  };
}

export {
  changePassword,
  logout,
  removeProfilePhoto,
  unlinkPayPal,
  unlinkStripe,
  updateFavorite,
  updateLoggedInUser,
};

const AuthenticatedUserActions = {
  changePassword,
  logout,
  removeProfilePhoto,
  unlinkPayPal,
  unlinkStripe,
  update: updateLoggedInUser,
  updateFavorite,
};

export default AuthenticatedUserActions;
