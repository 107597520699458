exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1F72r ul[role~=navigation] ._3EgIx{display:-ms-flexbox;display:flex;width:100%}._1F72r ul[role~=navigation] ._3EgIx li{-ms-flex:1 1;flex:1 1;margin:0 12px}._1F72r ul[role~=navigation] ._3EgIx li svg{margin-right:10px;display:none}._1F72r .link{display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center}._1F72r .link.active{color:var(--primary--400);color:var(--primary--400);-webkit-box-shadow:0 4px 0 var(--primary--400);box-shadow:0 4px 0 var(--primary--400);box-shadow:0 4px 0 var(--primary--400)}._1F72r .link.active path{fill:var(--primary--400);fill:var(--primary--400)}._1F72r .link .inner{position:relative;top:-1px}._3Es5x{font-weight:600;padding:24px 0 20px 0;width:100%;color:var(--neutral--900);color:var(--neutral--900);display:inline-block;white-space:nowrap;font-size:14px;vertical-align:middle;text-align:center;background-color:rgba(0,0,0,0);border:none}._1AyTy._1AyTy._1AyTy{color:#fff}._1kTqd._1kTqd{color:#fff;border:1px solid #fff}._2kLqS{padding:12px 20px;margin-top:9px;border-radius:30px;color:var(--primary--400);color:var(--primary--400);border:1px solid var(--primary--400);border:1px solid var(--primary--400);font-size:14px;font-weight:600;-webkit-box-shadow:none !important;box-shadow:none !important}@media(max-width: 992px){._1F72r{display:none}}", ""]);

// exports
exports.locals = {
	"root": "_1F72r",
	"navGroup": "_3EgIx",
	"link": "_3Es5x",
	"altLook": "_1AyTy",
	"mentorLinkAltLook": "_1kTqd",
	"mentorLink": "_2kLqS"
};