/*
Note: a relative `imgSrc` is relative to `${assetsRoot}images/`.
*/

const ObjectFreeze = Object.freeze;

const ART = ObjectFreeze({
  id: '10',
  nameEn: 'Art',
  descEn: 'Art.',
  nameMsgId: 'enums.expertise_categories.art.name',
  descMsgId: 'enums.expertise_categories.art.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url: 'shadowing/categories/art/art@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url: 'shadowing/categories/art/art@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url: 'shadowing/categories/art/art@640x400.jpg',
    },
  ]),
  imgSrc: 'shadowing/categories/art/art@1280x800.jpg',
  slug: 'art',
});

const BUSINESS = ObjectFreeze({
  id: '20',
  nameEn: 'Business',
  descEn: 'Business.',
  nameMsgId: 'enums.expertise_categories.business.name',
  descMsgId: 'enums.expertise_categories.business.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url: 'shadowing/categories/business/business@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url: 'shadowing/categories/business/business@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url: 'shadowing/categories/business/business@640x400.jpg',
    },
  ]),
  imgSrc: 'shadowing/categories/business/business@1280x800.jpg',
  slug: 'business',
});

const COMMUNICATION = ObjectFreeze({
  id: '30',
  nameEn: 'Communication',
  descEn: 'Communication.',
  nameMsgId: 'enums.expertise_categories.communication.name',
  descMsgId: 'enums.expertise_categories.communication.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url: 'shadowing/categories/communication/communication@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url: 'shadowing/categories/communication/communication@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url: 'shadowing/categories/communication/communication@640x400.jpg',
    },
  ]),
  imgSrc: 'shadowing/categories/communication/communication@1280x800.jpg',
  slug: 'communication',
});

const DESIGN = ObjectFreeze({
  id: '40',
  nameEn: 'Design',
  descEn: 'Design.',
  nameMsgId: 'enums.expertise_categories.design.name',
  descMsgId: 'enums.expertise_categories.design.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url: 'shadowing/categories/design/design@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url: 'shadowing/categories/design/design@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url: 'shadowing/categories/design/design@640x400.jpg',
    },
  ]),
  imgSrc: 'shadowing/categories/design/design@1280x800.jpg',
  slug: 'design',
});

const LIFESTYLE = ObjectFreeze({
  id: '50',
  nameEn: 'Lifestyle',
  descEn: 'Lifestyle.',
  nameMsgId: 'enums.expertise_categories.lifestyle.name',
  descMsgId: 'enums.expertise_categories.lifestyle.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url: 'shadowing/categories/lifestyle/lifestyle@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url: 'shadowing/categories/lifestyle/lifestyle@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url: 'shadowing/categories/lifestyle/lifestyle@640x400.jpg',
    },
  ]),
  imgSrc: 'shadowing/categories/lifestyle/lifestyle@1280x800.jpg',
  slug: 'lifestyle',
});

const MATH_AND_SCIENCE = ObjectFreeze({
  id: '60',
  nameEn: 'Math and Science',
  descEn: 'Math and Science.',
  nameMsgId: 'enums.expertise_categories.math_and_science.name',
  descMsgId: 'enums.expertise_categories.math_and_science.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/math-and-science/math-and-science@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/math-and-science/math-and-science@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url: 'shadowing/categories/math-and-science/math-and-science@640x400.jpg',
    },
  ]),
  imgSrc: 'shadowing/categories/math-and-science/math-and-science@1280x800.jpg',
  slug: 'math-and-science',
});

const MUSIC = ObjectFreeze({
  id: '70',
  nameEn: 'Music',
  descEn: 'Music.',
  nameMsgId: 'enums.expertise_categories.music.name',
  descMsgId: 'enums.expertise_categories.music.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url: 'shadowing/categories/music/music@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url: 'shadowing/categories/music/music@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url: 'shadowing/categories/music/music@640x400.jpg',
    },
  ]),
  imgSrc: 'shadowing/categories/music/music@1280x800.jpg',
  slug: 'music',
});

const PERFORMING_ARTS = ObjectFreeze({
  id: '80',
  nameEn: 'Performing Arts',
  descEn: 'Performing Arts.',
  nameMsgId: 'enums.expertise_categories.performing_arts.name',
  descMsgId: 'enums.expertise_categories.performing_arts.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url: 'shadowing/categories/performing-arts/performing-arts@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url: 'shadowing/categories/performing-arts/performing-arts@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url: 'shadowing/categories/performing-arts/performing-arts@640x400.jpg',
    },
  ]),
  imgSrc: 'shadowing/categories/performing-arts/performing-arts@1280x800.jpg',
  slug: 'performing-arts',
});

const PHOTOGRAPHY = ObjectFreeze({
  id: '90',
  nameEn: 'Photography',
  descEn: 'Photography.',
  nameMsgId: 'enums.expertise_categories.photography.name',
  descMsgId: 'enums.expertise_categories.photography.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url: 'shadowing/categories/photography/photography@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url: 'shadowing/categories/photography/photography@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url: 'shadowing/categories/photography/photography@640x400.jpg',
    },
  ]),
  imgSrc: 'shadowing/categories/photography/photography@1280x800.jpg',
  slug: 'photography',
});

const SOCIETY = ObjectFreeze({
  id: '100',
  nameEn: 'Society',
  descEn: 'Society.',
  nameMsgId: 'enums.expertise_categories.society.name',
  descMsgId: 'enums.expertise_categories.society.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url: 'shadowing/categories/society/society@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url: 'shadowing/categories/society/society@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url: 'shadowing/categories/society/society@640x400.jpg',
    },
  ]),
  imgSrc: 'shadowing/categories/society/society@1280x800.jpg',
  slug: 'society',
});

const SPORTS_AND_RECREATION = ObjectFreeze({
  id: '110',
  nameEn: 'Sports and Recreation',
  descEn: 'Sports and Recreation.',
  nameMsgId: 'enums.expertise_categories.sports_and_recreation.name',
  descMsgId: 'enums.expertise_categories.sports_and_recreation.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url:
        'shadowing/categories/sports-and-recreation/sports-and-recreation@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url:
        'shadowing/categories/sports-and-recreation/sports-and-recreation@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url:
        'shadowing/categories/sports-and-recreation/sports-and-recreation@640x400.jpg',
    },
  ]),
  imgSrc:
    'shadowing/categories/sports-and-recreation/sports-and-recreation@1280x800.jpg',
  slug: 'sports-and-recreation',
});

const TECHNOLOGY = ObjectFreeze({
  id: '120',
  nameEn: 'Technology',
  descEn: 'Technology.',
  nameMsgId: 'enums.expertise_categories.technology.name',
  descMsgId: 'enums.expertise_categories.technology.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url: 'shadowing/categories/technology/technology@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url: 'shadowing/categories/technology/technology@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url: 'shadowing/categories/technology/technology@640x400.jpg',
    },
  ]),
  imgSrc: 'shadowing/categories/technology/technology@1280x800.jpg',
  slug: 'technology',
});

const TRADE_SKILLS = ObjectFreeze({
  id: '130',
  nameEn: 'Trade Skills',
  descEn: 'Trade Skills.',
  nameMsgId: 'enums.expertise_categories.trade_skills.name',
  descMsgId: 'enums.expertise_categories.trade_skills.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url: 'shadowing/categories/trade-skills/trade-skills@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url: 'shadowing/categories/trade-skills/trade-skills@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url: 'shadowing/categories/trade-skills/trade-skills@640x400.jpg',
    },
  ]),
  imgSrc: 'shadowing/categories/trade-skills/trade-skills@1280x800.jpg',
  slug: 'trade-skills',
});

const VIDEO = ObjectFreeze({
  id: '140',
  nameEn: 'Video',
  descEn: 'Video.',
  nameMsgId: 'enums.expertise_categories.video.name',
  descMsgId: 'enums.expertise_categories.video.desc',
  imageSources: ObjectFreeze([
    {
      descriptor: '1920w',
      url: 'shadowing/categories/video/video@1920x1200.jpg',
    },
    {
      descriptor: '1280w',
      url: 'shadowing/categories/video/video@1280x800.jpg',
    },
    {
      descriptor: '640w',
      url: 'shadowing/categories/video/video@640x400.jpg',
    },
  ]),
  imgSrc: 'shadowing/categories/video/video@1280x800.jpg',
  slug: 'video',
});

// Note: The CategoryPicker depends on the order of these values.
const values = ObjectFreeze([
  ART,
  BUSINESS,
  COMMUNICATION,
  DESIGN,
  LIFESTYLE,
  MATH_AND_SCIENCE,
  MUSIC,
  PERFORMING_ARTS,
  PHOTOGRAPHY,
  SOCIETY,
  SPORTS_AND_RECREATION,
  TECHNOLOGY,
  TRADE_SKILLS,
  VIDEO,
]);

// const ids = ObjectFreeze(values.map(category => category.id));
// const slugs = ObjectFreeze(values.map(category => category.slug));

const BY_ID_LUT = {};
values.forEach(category => {
  BY_ID_LUT[category.id] = category;
});
ObjectFreeze(BY_ID_LUT);

function findById(id) {
  return BY_ID_LUT[id];
}

const BY_SLUG_LUT = {};
values.forEach(category => {
  BY_SLUG_LUT[category.slug] = category;
});
ObjectFreeze(BY_SLUG_LUT);

function findBySlug(slug) {
  return BY_SLUG_LUT[slug];
}

const ExpertiseCategories = {
  ART,
  BUSINESS,
  COMMUNICATION,
  DESIGN,
  LIFESTYLE,
  MATH_AND_SCIENCE,
  MUSIC,
  PERFORMING_ARTS,
  PHOTOGRAPHY,
  SOCIETY,
  SPORTS_AND_RECREATION,
  TECHNOLOGY,
  TRADE_SKILLS,
  VIDEO,
  findById,
  findBySlug,
  values,
};

export default ExpertiseCategories;
