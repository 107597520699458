import cx from 'classnames';
import PTs from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import React from 'react';

import s from './Grid.scss';

const Grid = ({ children, snaps }) => (
  <div className={cx(s.grid, { [s.snaps]: snaps }, 'grid')}>{children}</div>
);

Grid.propTypes = {
  children: PTs.node,
  // Indicates whether the grid should snap to pre-defined widths for different
  // viewport widths.  When set to `false` (the default), then the grid's width
  // will be fluid.
  snaps: PTs.bool,
};

Grid.defaultProps = {
  children: null,
  snaps: false,
};

export default withStyles(s)(Grid);
