/* eslint-disable */
import PTs from 'prop-types';

/**
 * Represents a particular datetime in an particular timezone.
 */
const DateTimeStructShape = PTs.shape({
  year: PTs.number.isRequired, // E.g, 2018
  month: PTs.number.isRequired, // 1 to 12
  day: PTs.number.isRequired, // 1 to 31
  hours: PTs.number, // 0 to 23; defaults to 0.
  minutes: PTs.number, // 0 to 59; defaults to 0.
  seconds: PTs.number, // 0 to 59; defaults to 0.
  milliseconds: PTs.number, // 0 to 999; defaults to 0.
});

export default DateTimeStructShape;
