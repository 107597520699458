import cx from 'classnames';
import pick from 'lodash/pick';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import PTs from 'prop-types';
import React from 'react';
import AsyncSelect from 'react-select/lib/Async';

import injectAppContext from '../../components/AppContext/injectAppContext';

import AppContextShape from '../../lib/AppContextShape';
import ExpertiseCategories from '../../lib/ExpertiseCategories';
import ExpertiseFields from '../../lib/ExpertiseFields';

import getAreasOfExpertiseQuery from '../../queries/getAreasOfExpertiseQuery';

import '../../styles/react-select.module.scss';

import s from './CategoryPicker.scss';

const allGroupOptions = [];

ExpertiseCategories.values.forEach(cat => {
  allGroupOptions.push({
    label: cat.nameEn,
    value: cat.id,
    catId: cat.id,
    catSlug: cat.slug,
    fldId: null, // Used to indicate that this is a category option.
    fldSlug: null,
    options: ExpertiseFields.values
      .filter(fld => fld.expertiseCategoryId === cat.id)
      .map(fld => ({
        label: fld.nameEn,
        value: fld.id,
        catId: cat.id,
        catSlug: cat.slug,
        fldId: fld.id,
        fldSlug: fld.slug,
      })),
  });
});

const GRP_OPT_FLD_NMS = [
  'catId',
  'catSlug',
  'fldId',
  'fldSlug',
  'label',
  'value',
];

const STYLE__DISPLAY_NONE = { display: 'none' };

function indicatorsContainerStyle() {
  return STYLE__DISPLAY_NONE;
}

function noOptionsMessage({ inputValue }) {
  if (inputValue.trim().length === 0) {
    return 'Currently, no shadowing opportunities are available. Please check back soon.';
  }
  return `Found no categories matching "${inputValue}".`;
}

const selectStyles = {
  indicatorsContainer: indicatorsContainerStyle,
};

class CategoryPicker extends React.PureComponent {
  keyDownTimerId = null;

  state = {
    inputValue: '',
  };

  componentWillUnmount() {
    clearTimeout(this.keyDownTimerId);
  }

  /* eslint-disable-next-line arrow-body-style */
  formatOptionLabel = (option, meta_unused) => {
    return (
      <span
        className={cx('react-select__option__label', {
          'is-category': option.fldId === null,
        })}
      >
        {option.label}
      </span>
    );
  };

  handleChange = (option, meta) => {
    /*
    type Action = "clear" | "create-option" | "deselect-option" | "pop-value" | "remove-value" | "select-option" | "set-value";
    */
    const { onSelect } = this.props;
    const { action } = meta;

    if (action === 'select-option') {
      if (typeof onSelect === 'function') {
        clearTimeout(this.keyDownTimerId);
        onSelect(option);
      }
    }
  };

  handleInputChange = (inputValue, meta) => {
    const { action } = meta;

    if (action === 'input-change') {
      this.setState({ inputValue });
    }
  };

  handleKeyDown = evt => {
    const { onEnter } = this.props;

    if (evt.key === 'Enter') {
      if (typeof onEnter === 'function') {
        /*
        HACK: The react-select component will select an option if one is focused in the
        menu when Enter is pressed. In this case, we want to effectively ignore Enter
        being pressed. That is, we don't want to call both `onEnter` and `onSelect`
        both for this single event -- we just want to call one of them.
        */
        clearTimeout(this.keyDownTimerId);
        this.keyDownTimerId = setTimeout(() => {
          onEnter(this.state.inputValue);
        }, 1);
      }
    }
  };

  loadOptions = inputValue =>
    new Promise((resolve, reject_unused) => {
      const { appContext } = this.props;
      const { graphqlClient } = appContext;

      const queryResult = graphqlClient.query({
        query: getAreasOfExpertiseQuery,
      });

      queryResult.then(res => {
        const categoryQueryData = res.data;
        const populatedCategories = categoryQueryData.expertiseCategories.edges.filter(
          cat => cat.expertCount > 0,
        );
        const populatedCategoryAndFieldNames = [];
        populatedCategories.forEach(cat => {
          populatedCategoryAndFieldNames.push(cat.node.name);
          cat.node.fieldsConnection.edges.forEach(fld => {
            if (fld.expertCount > 0) {
              populatedCategoryAndFieldNames.push(fld.node.name);
            }
          });
        });

        const matchingGroupOptions = [];

        const searchTerm = inputValue.toLowerCase();
        if (searchTerm) {
          allGroupOptions.forEach(group => {
            const groupLabel = group.label;

            if (groupLabel.toLowerCase().includes(searchTerm)) {
              const groupOption = { ...pick(group, GRP_OPT_FLD_NMS) };
              matchingGroupOptions.push(groupOption);
              group.options.forEach(option =>
                matchingGroupOptions.push(option),
              );
            } else {
              const groupOptions = group.options.filter(option =>
                option.label.toLowerCase().includes(searchTerm),
              );
              if (groupOptions.length > 0) {
                // const groupOption = { ...pick(group, GRP_OPT_FLD_NMS) };
                // matchingGroupOptions.push(groupOption);
                groupOptions.forEach(option =>
                  matchingGroupOptions.push(option),
                );
              }
            }
          });
        } else {
          allGroupOptions.forEach(group => {
            const groupOption = { ...pick(group, GRP_OPT_FLD_NMS) };
            matchingGroupOptions.push(groupOption);
            group.options.forEach(option => matchingGroupOptions.push(option));
          });
        }
        const mGO = matchingGroupOptions.filter(group =>
          populatedCategoryAndFieldNames.includes(group.label),
        );

        resolve(mGO);
      });
    });

  render() {
    const { className, style, isSearchOpen, ...selectProps } = this.props;
    delete selectProps.onSelect;
    return (
      <div
        className={
          isSearchOpen
            ? cx(className, s.root)
            : cx(className, s.root, s.searchHidden)
        }
        style={style}
      >
        <AsyncSelect
          backspaceRemovesValue={false}
          className="react-select"
          classNamePrefix="react-select"
          closeMenuOnSelect={false}
          defaultOptions
          formatOptionLabel={this.formatOptionLabel}
          isSearchable
          loadOptions={this.loadOptions}
          noOptionsMessage={noOptionsMessage}
          onChange={this.handleChange}
          onInputChange={this.handleInputChange}
          onKeyDown={this.handleKeyDown}
          styles={selectStyles}
          {...selectProps}
          // menuIsOpen // Enable this prop to see menu in dev tools.
        />
      </div>
    );
  }
}

CategoryPicker.propTypes = {
  appContext: AppContextShape.isRequired,
  className: PTs.string,
  isSearchOpen: PTs.bool.isRequired,
  onEnter: PTs.func,
  onSelect: PTs.func,
  placeholder: PTs.string,
  style: PTs.shape({}),
};

CategoryPicker.defaultProps = {
  className: '',
  onEnter: null,
  onSelect: null,
  placeholder: 'What do you want to learn?',
  style: undefined,
};

export default withStyles(s)(injectAppContext(CategoryPicker));
