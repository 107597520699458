import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import PTs from 'prop-types';
import React from 'react';
import {
  faCalendarAlt,
  faEnvelope,
  faComment,
} from '@fortawesome/free-regular-svg-icons';
import { faTachometerAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Link from '../../../components/Link';

// import config from '../../../config/client-config';

import s from './UserPagesNavSection.scss';

// const appRoot = config.app.root;

function UserPagesNavSection(props) {
  const { isPageScrolledToTop, look } = props;

  // const profilesPathRe = new RegExp(
  //   `^${escapeStringRegexp(appRoot)}user/profiles?/`,
  // );
  return (
    <section className={s.root}>
      <ul role="navigation">
        <div className={s.navGroup}>
          <li style={{ minWidth: '155px' }}>
            <Link
              className={
                look === 'secondary' && isPageScrolledToTop
                  ? cx(s.mentorLink, s.mentorLinkAltLook)
                  : s.mentorLink
              }
              to="/handbook"
            >
              <FontAwesomeIcon icon={faComment} />
              Become a mentor
            </Link>
          </li>
          <li>
            <Link
              className={
                look === 'secondary' && isPageScrolledToTop
                  ? cx(s.link, s.altLook)
                  : s.link
              }
              to="/user/dashboard"
            >
              <FontAwesomeIcon icon={faTachometerAlt} />
              Dashboard
            </Link>
          </li>
          {/* <li>
            <Link
              className={cx('-e2e-profile-link')}
              isActive={currPath => profilesPathRe.test(currPath)}
              to="/user/profile"
            >
              {' '}
              <FontAwesomeIcon icon={faAddressCard} />
              <p>Profiles</p>
            </Link>
          </li> */}
          <li>
            <Link
              className={
                look === 'secondary' && isPageScrolledToTop
                  ? cx(s.link, s.altLook)
                  : s.link
              }
              to="/user/inbox"
            >
              <FontAwesomeIcon icon={faEnvelope} />
              Inbox
            </Link>
          </li>
          <li>
            <Link
              className={
                look === 'secondary' && isPageScrolledToTop
                  ? cx(s.link, s.altLook)
                  : s.link
              }
              to="/user/calendar/shadower"
            >
              <FontAwesomeIcon icon={faCalendarAlt} />
              Calendar
            </Link>
          </li>
          {/* <li>
            <Link to="/help">
              <FontAwesomeIcon icon={faQuestionCircle} />
              <p>Help</p>
            </Link>
          </li>
          <li>
            <Link to="/user/settings">
              <FontAwesomeIcon icon={faCog} />
              <p>Settings</p>
            </Link>
          </li>
          <li>
            <Link to="/user/information">
              <FontAwesomeIcon icon={faInfoCircle} />
              Information
            </Link>
          </li> */}
        </div>
      </ul>
    </section>
  );
}

UserPagesNavSection.propTypes = {
  isPageScrolledToTop: PTs.bool.isRequired,
  look: PTs.oneOf(['default', 'secondary']),
};
UserPagesNavSection.defaultProps = {
  look: 'default',
};

export default withStyles(s)(UserPagesNavSection);
